import React from "react";
import { ReactComponent as Eye } from "../../../assets/eye.svg";

export function Password({
  showCalculation,
  score = 0,
  message,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement> & {showCalculation?: boolean, score?: number, message?: string}) {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  
  return (
    <>
    <div className="w-full relative">
      <input
        {...props}
        className={`!outline-none !pr-10 w-full bg-ultraLightGray focus:bg-white rounded-lg border border-lightGray focus:border-grey2 p-5 text-base text-black ${
          props.className || ""
        }`}
        type={showPassword ? "text" : "password"}
      />
      <button
        type="button"
        className="absolute right-4 top-0 bottom-0"
        onClick={() => setShowPassword(!showPassword)}
      >
        <Eye />
      </button>
    </div>
      {showCalculation && <div className="flex flex-col items-center gap-1 mt-4">
        <div className="w-full h-1 overflow-hidden rounded-full bg-gray-200">
          <div
            className={`h-1 rounded-full ${score >= 80 ? "bg-[#0AA630]" : "bg-red-600"}`}
            style={{ width: `${score}%` }}
          ></div>
        </div>
        <div className="flex justify-between items-center w-full">
        <div className="text-zinc-500 text-xs font-normal font-['Inter']">{message}</div>
        <div className={`text-right ${score >= 80 ? "text-green-600" : "text-red-600"} text-xs font-medium`}>{score >= 80 ? "Strong" : "Weak"}</div>
        </div>
      </div>}
    </>
  );
}
