import { Layout } from "./components/Layout";
import { Button } from "../../components/Button";
import { TextInput } from "../../components/TextInput";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useMemo } from "react";
import { api } from "../../services/api";
import {
  checkPasswordStrength,
  getUser,
  setToken,
  setUser,
  validateEmail,
} from "../../services/utils";
import { Separator } from "./components/Separator";
import { SocialLogin } from "./SocialLogin";
import { Password } from "./components/Password";
import { ConfirmEmail } from "./ConfirmEmail";
import { ReactComponent as AlertIcon } from "../../assets/Alert.svg";
import { pageView } from "../../services/mixpanel";
import TagManager from "react-gtm-module";
import { GTM_ID } from "../../constant";

export function Signup() {
  const navigate = useNavigate();
  const [showOnboarding, setShowOnboarding] = React.useState(false);
  const [showConfirmView, setShowConfirmView] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [isPasswordSame, setIsPasswordSame] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [emailError, setEmailError] = React.useState<string>("");
  const [phoneError, setPhoneError] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");

  const sendVerificationCode = async (userId) => {
    if (!userId) return;
    const response = await api.initEmailVerification(userId);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // get the form data
    const formData = new FormData(e.currentTarget);
    // convert the form data to an object
    const data = Object.fromEntries(formData);
    if (data.password !== data.confirmPassword) {
      setIsPasswordSame("Confirm password is not the same");
      return;
    }
    setIsPasswordSame("");
    // log the results
    try {
      setLoading(true);
      const response = await api.signup({
        email: data.email,
        password: data.password,
        mobile: data.mobile,
      });
      if (response.accessToken) {
        // Call GTM dataLayer

        const tagManagerArgs = {
          gtmId: GTM_ID,
          dataLayer: {
            event: "signUp",
            userId: response._id,
            email: response.email,
          },
        };
        if (TagManager.dataLayer) {
          TagManager.dataLayer(tagManagerArgs);
        }
        setToken(response.accessToken);
        setUser({ ...response });
        // setShowOnboarding(true);
        //show confirm email view
        await sendVerificationCode(response._id);
        setShowConfirmView(true);
      }
      setLoading(false);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setError(error.response.data.message);
      } else {
        setError("Something went wrong");
      }
      setLoading(false);
    }
  };

  const handleValidateEmail = (e: any) => {
    const value = e.target.value;
    const isValidEmail = validateEmail(value);

    if (!isValidEmail) {
      setEmailError("Please enter a valid email");
    } else {
      setEmailError("");
    }
  };

  const validatePhone = (phone: string): boolean => {
    return !!phone.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
  };

  const handleValidatePhone = (e: any) => {
    const value = e.target.value;

    // allow only number
    const regex = /^[0-9\b]+$/;
    if (value === "" || regex.test(value)) {
      if (value && !validatePhone(value.toString())) {
        setPhoneError("Invalid mobile number");
      } else {
        setPhoneError("");
      }
    } else {
      setPhoneError("Invalid mobile number");
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    setPassword(data.password.toString());

    const isValidEmail = validateEmail(data?.email?.toString());

    if (isValidEmail) {
      setEmailError("");
    }

    const isValidPhone = validatePhone(data?.mobile?.toString());

    if (
      data.email &&
      data.password &&
      data.mobile &&
      data.confirmPassword &&
      data.termCondition &&
      data.password.toString().length >= 8 &&
      data.confirmPassword.toString().length >= 8 &&
      isValidEmail &&
      isValidPhone
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleOnConfirmEmail = () => {
    setShowConfirmView(false);
    // setShowOnboarding(true);
    // TODO: on signup it should redirect to payment page
    const user = getUser();
    setUser({ ...user, isEmailVerified: true });
    // navigate("/subscription");
    navigate("/onboarding");
  };

  const handleSocialLoginSuccess = (data: any) => {
    // setShowOnboarding(true);
    // TODO: on signup it should redirect to payment page
    // navigate("/subscription");
    navigate("/onboarding");
  };

  const { score, message } = useMemo(() => {
    return checkPasswordStrength(password);
  }, [password]);

  useEffect(() => {
    pageView();
  }, []);

  if (showConfirmView) {
    return (
      <ConfirmEmail
        onSuccess={handleOnConfirmEmail}
        onClose={() => setShowConfirmView(false)}
      />
    );
  }

  return (
    <Layout>
      <div className="flex flex-col w-full md:w-[438px] max-h-[calc(100vh_-_100px)] overflow-auto">
        <div className="rounded-lg p-6 bg-white flex flex-col mx-3">
          <div className="text-dark1 text-2xl font-semibold mb-2">Sign up</div>
          <span className="text-dark2 text-sm font-normal">
            Create your account
          </span>
          <form
            onSubmit={handleSubmit}
            className="flex-1"
            onChange={handleChange}
          >
            {error && (
              <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                <AlertIcon /> {error}
              </div>
            )}
            <div className="my-6 mt-5 flex flex-col gap-5">
              <div className="flex flex-col gap-1.5">
                <span className="text-black text-xs font-medium">
                  Phone number
                </span>
                <TextInput
                  type="tel"
                  placeholder="Your phone number"
                  className="w-full !p-[14px]"
                  name="mobile"
                  maxLength={10}
                  onBlur={handleValidatePhone}
                />
                {phoneError && (
                  <span className="text-red-500 text-xs font-medium">
                    {phoneError}
                  </span>
                )}
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-black text-xs font-medium">Email</span>
                <TextInput
                  placeholder="Your email"
                  className="w-full !p-[14px]"
                  name="email"
                  onBlur={handleValidateEmail}
                />
                {emailError && (
                  <span className="text-red-500 text-xs font-medium">
                    {emailError}
                  </span>
                )}
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-black text-xs font-medium">Password</span>
                <Password
                  placeholder="Your password"
                  className="!p-[14px]"
                  name="password"
                  showCalculation
                  score={score}
                  message={message}
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-black text-xs font-medium">
                  Confirm Password
                </span>
                <Password
                  type="password"
                  placeholder="Your confirmed password"
                  className="!p-[14px]"
                  name="confirmPassword"
                />
                {isPasswordSame && (
                  <span className="text-red-500 text-xs font-medium">
                    {isPasswordSame}
                  </span>
                )}
              </div>
            </div>
            <div className="mb-6 justify-end items-start gap-2 inline-flex">
              <input
                name="termCondition"
                type="checkbox"
                className="min-w-[24px] min-h-[24px] accent-primary hover:accent-primary"
              />
              <div className="text-zinc-500 text-xs font-normal whitespace-wrap leading-tight">
                By clicking Create account, I agree that I have read and
                accepted the{" "}
                <a
                  href="https://guidely.com/terms-of-use"
                  target="_blank"
                  className="text-blue-500"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  href="https://guidely.com/privacy-policy"
                  target="_blank"
                  className="text-blue-500"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </div>
            </div>
            <Button
              disabled={disabled}
              loading={loading}
              className="w-full"
              variant="primary"
            >
              Create
            </Button>
            <Separator />
            <SocialLogin
              responseFacebook={() => {}}
              onSuccess={handleSocialLoginSuccess}
            />
          </form>
        </div>
        <div className="flex text-sm text-dark2 gap-1.5 mt-4 justify-center">
          Already have an account?
          <Link
            to="/login"
            className="text-black border-b pb-1 border-dashed border-primary font-medium"
          >
            Sign in
          </Link>
        </div>
      </div>
    </Layout>
  );
}
