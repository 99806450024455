import React from 'react'
import {Button} from '../Button'
import {BrandColors} from '../BrandColors'
import {BrandFonts} from '../BrandFonts'
import {useLAFContext} from './LAF.context'
import {Loading} from '../Loading/Loading'

export type LookAndFeelParams = {
  isExitButtonEnabled?: boolean
  isHeadlineAllowed?: boolean
  handleExitButton?: () => void
}

export const LookAndFeel = ({
                              isExitButtonEnabled,
                              handleExitButton,
                              isHeadlineAllowed = true,
                            }: LookAndFeelParams) => {
  const {
    colors,
    fonts,
    loading,
    onUpdateBrandColors,
    onUpdateBrandFonts,
    onRefreshColor,
    onRefreshTypography,
    currentStepData,
  } = useLAFContext()

  if (loading) {
    return <Loading/>
  }

  return <>
    {isHeadlineAllowed ? <div className={'flex place-content-between'}>
      <div>
        <h1 className="text-dark1 font-semibold ss:pb-2 xs:pb-2 ss:leading-7 ss:text-xl lg:text-2xl md:pb-0 xl:leading-12">
          {currentStepData?.title}
        </h1>
      </div>
      {isExitButtonEnabled ? <div className="items-end xs:hidden ss:hidden sm:flex">
        <Button variant={'primary'}
                className="btn btn-primary relative float-right right-1 top-1 mt-0 mr-0 mb-5 ml-2 p-3"
                onClick={handleExitButton}>
          Exit
        </Button>
      </div> : null}
    </div> : null}
    {isHeadlineAllowed ? <div>
      <p className={'text-base text-[#484848]'}>{currentStepData?.description}</p>
    </div> : null}
    <div
        className="flex flex-col gap-4 md:flex-row mt-5">
      <div className={'ss:flex-1 md:flex md:w-full'}>
        <div className={'ss:w-full md:w-3/5'}>
          <BrandColors colors={colors}
                       onUpdate={onUpdateBrandColors}
                       onRefresh={onRefreshColor}
          />
        </div>
        <div
            className={'p-5 self-center text-base text-[#484848] ss:w-full md:w-2/5'}>
          The colors will be used when creating images for your feed.
          You can choose new colors with the color wheel or enter in
          specific hex codes.
        </div>
      </div>
    </div>
    <div className="flex flex-col gap-4 md:flex-row">
      <div className={'ss:flex-1 md:flex w-full'}>
        <div className={'ss:w-full md:w-3/5'}>
          <BrandFonts fonts={fonts} onUpdate={onUpdateBrandFonts}
                      onRefresh={onRefreshTypography}/>
        </div>
        <div
            className={'p-5 self-center text-base text-[#484848] ss:w-full md:w-2/5'}>
          These fonts will be overlayed on top of your images. We use an
          approved list of Google Fonts that were hand-selected for our
          custom Instagram templates.
        </div>
      </div>
    </div>
  </>
}