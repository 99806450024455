import { useEffect, useState } from "react";
import { LoadingScreen } from "../profile/LoadingScreen";
import { HighlightCard } from "./components/HightlightCard";

import { ReactComponent as Library } from "../../assets/ic_my_library_books.svg";
import { ReactComponent as People } from "../../assets/ic_people.svg";
import { ReactComponent as Poll } from "../../assets/ic_poll.svg";
import { ReactComponent as PersonAdd } from "../../assets/ic_person_add.svg";
import { ReactComponent as ThumbDown } from "../../assets/ic_thumb_down.svg";
import { ReactComponent as ThumbUp } from "../../assets/ic_thumb_up.svg";
import { ReactComponent as Favourite } from "../../assets/ic_favorite.svg";
import { ReactComponent as Bookmark } from "../../assets/ic_bookmark.svg";
import { ReactComponent as Send } from "../../assets/ic_send.svg";
import { ReactComponent as CommentIcon } from "../../assets/ic_comment.svg";
import { ReactComponent as Info } from "../../assets/ic_info.svg";

import { useUser } from "../profile/context/user-context";
import userImage from "../../assets/user.png";
import progressImage from "../../assets/progress-image.png";
import progressBackground from "../../assets/progress-bg.png";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import moment from "moment";
import { formatNumber, getUser } from "../../services/utils";
import { api } from "../../services/api";
import { SelectInput } from "../../components/SelectInput";
import Tippy from "@tippyjs/react";
import { pageView } from "../../services/mixpanel";
import { ConnectInstagram } from "../onboarding/components/ConnectInstagram";
import { useOutletContext } from "react-router-dom";
import { GetStarted } from "./components/GetStarted";
import { ShowToast } from "../../services/toast";
import { useApp } from "../../services/app-context";
import { PaymentBox } from "../dashboard/components/PaymentBox";

const calculatePostInsights = (data: any[]) => {
  if (!data)
    return {
      total: 0,
      max: {},
      min: {},
      maxPercentage: 0,
      minPercentage: 0,
    };
  const total = data?.reduce((acc: any, item: any) => {
    return acc + item.reach;
  }, 0);
  // get object with max value
  const max = data?.reduce(
    (prev: any, current: any) => (prev.reach > current.reach ? prev : current),
    {}
  );
  // get object with min value
  const min = data?.reduce(
    (prev: any, current: any) => (prev.reach < current.reach ? prev : current),
    {}
  );
  const maxPercentage = (max.reach / total) * 100;
  const minPercentage = (min.reach / total) * 100;
  return {
    total,
    max,
    min,
    maxPercentage,
    minPercentage,
  };
};

function CustomizedLabel({ x, y, payload, ...rest }) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={6} y={0} dy={16} textAnchor="end" fill="#666">
        {payload.value.split("")[0]}
      </text>
    </g>
  );
}

export function Progress() {
  const user = useUser() as any;
  const { isSubscriptionActive } = useApp() as any;
  const [filter, setFilter] = useState(
    moment().subtract(0, "months").format("YYYY-MM")
  );
  const { instagramConnected, fetchInstagramConnected } = useOutletContext<{
    instagramConnected: boolean;
    fetchInstagramConnected: (userId: string) => void;
  }>();
  const [loading, setLoading] = useState(false);
  const [selectedWeeklyInsight, setSelectedWeeklyInsight] = useState(0);
  const [progressData, setProgressData] = useState<any>({});
  const [fetchingPosts, setFetchingPosts] = useState(false);
  const { userInfo, fetchUser } = useUser() as any;
  const { _id: userId, ...rest } = getUser();
  const [instagramConnectModal, setInstagramConnectModal] = useState(false);

  const fetchData = async (userId, filter, instagramConnected) => {
    if (!instagramConnected) {
      return;
    }
    const month = moment(filter).format("MM");
    const year = moment(filter).format("YYYY");
    try {
      setLoading(true);
      api.getProgressDashboard(userId, month, year).then((response) => {
        setLoading(false);
        setProgressData(response);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleRefresh = (instagramConnected) => {
    fetchData(userId, filter, instagramConnected);
  };

  useEffect(() => {
    fetchData(userId, filter, instagramConnected);
  }, [userId, filter, instagramConnected]);

  const currentWeekData = progressData?.weeklyPostInsights
    ? progressData?.weeklyPostInsights[selectedWeeklyInsight]
    : [];

  const profileGrowthData = progressData?.profileGrowth?.weeklyData || [];

  const postInsights = calculatePostInsights(currentWeekData || []);

  const handleInstagramModalClose = () => {
    setInstagramConnectModal(false);
    // handleRefresh();
  };

  const handleInstagramConnectRefresh = () => {
    handleRefresh(true);
    fetchInstagramConnected(userId);
    fetchUser(userId);
  };

  const handleConfirmConsent = async () => {
    const response = await api.saveUserProfile(userId, {
      canAutoSchedulePost: true,
    });
    if ([200, 201].includes(response.status)) {
      await fetchUser(userId);
    } else {
      ShowToast({
        type: "error",
        message: "Error confirming consent",
      });
    }
  };

  // month start date and end date based on selected filter
  // it should take exact one month old date as start date
  // and current date as end date
  const startDate = moment(filter).subtract(1, "months");
  const endDate = moment(filter);

  useEffect(() => {
    pageView();
  }, []);

  if (loading) {
    return (
      <div className="relative bg-ultraLightGray p-4 md:p-6">
        <LoadingScreen />
      </div>
    );
  }
  return (
    <div className="relative bg-ultraLightGray p-4 md:p-6">
      <div className="max-w-[1250px] mx-auto">
        <div className="flex flex-wrap gap-4 md:justify-between items-center mb-8">
          <div className="flex justify-between items-center flex-1 [&>div]:!w-fit">
            <div className="flex gap-2 items-center">
              <h1 className="text-neutral-800 text-4xl font-semibold leading-10">
                Progress
              </h1>
              <Tippy
                className="my-tooltip"
                arrow={true}
                interactive={true}
                hideOnClick={true}
                content={
                  <div className=" p-4 bg-white rounded-[10px] shadow">
                    After your first full month using Guidely you'll see more
                    detailed metrics. Check back frequently to measure your
                    progress.
                  </div>
                }
              >
                <div className="flex items-center gap-1 mt-2 cursor-pointer">
                  <Info />
                </div>
              </Tippy>
            </div>
            <SelectInput
              showBlankOption={false}
              data={[
                // last 12 months select like Jan 2021, Feb 2021, etc
                // if it is current month, then show this month as label
                // value must be equal or grater than joinedDate
                ...Array(12)
                  .fill(0)
                  .map((_, index) => ({
                    label:
                      index === 0
                        ? "This month"
                        : moment().subtract(index, "months").format("MMM YYYY"),
                    value: moment().subtract(index, "months").format("YYYY-MM"),
                  }))
                  .filter((item) => {
                    const joinedMonth = moment(userInfo?.joinedDate).startOf(
                      "month"
                    );
                    return moment(item.value)
                      .startOf("month")
                      .isSameOrAfter(joinedMonth);
                  }),
              ]}
              value={filter}
              onChange={(e: any) => {
                setFilter(e.target.value);
                // handleRegenerate({...postForm, type: e.target.value})
              }}
              className="!py-2 !bg-white !rounded"
              disabled={!isSubscriptionActive}
            />
          </div>
        </div>
        {!isSubscriptionActive ? (
          <PaymentBox
            title="Start Your 30-Day Free Trial"
            description="This section allows you to see how your Instagram account is performing and how it's growing over time. Unlike other 
            tools, once you join Guidely we begin tracking the performance of all your posts, not just those you post with Guidely"
          />
        ) : !instagramConnected ? (
          <GetStarted
            onInstagramConnect={() => {
              handleConfirmConsent();
              setInstagramConnectModal(true);
            }}
          />
        ) : null}
        {instagramConnected && isSubscriptionActive ? (
          <>
            <div className="flex flex-col gap-6">
              <div className="flex flex-col md:flex-row gap-6 flex-wrap">
                <HighlightCard
                  label="Total Posts"
                  value={progressData?.totalPosts}
                  icon={<Library />}
                  progressColor={
                    (progressData?.postsGrowthRate || 0) < 0
                      ? "text-[#FC0000]"
                      : "text-[#0AA630]"
                  }
                  progressRate={`${
                    (progressData?.postsGrowthRate || 0) < 0 ? "-" : "+"
                  }
              ${progressData?.postsGrowthRate || 0} %`}
                  onClick={() => {}}
                  className="max-w-full md:max-w-[300px]"
                />
                <HighlightCard
                  label="Followers"
                  value={progressData?.totalFollowers}
                  icon={<People />}
                  progressColor={
                    (progressData?.followersGrowthRate || 0) < 0
                      ? "text-[#FC0000]"
                      : "text-[#0AA630]"
                  }
                  progressRate={`${
                    (progressData?.followersGrowthRate || 0) < 0 ? "-" : "+"
                  }
              ${progressData?.followersGrowthRate || 0} %`}
                  onClick={() => {}}
                  className="max-w-full md:max-w-[300px]"
                />
                <HighlightCard
                  label="Following"
                  value={progressData?.totalFollows}
                  icon={<PersonAdd className="[&>path]:fill-primary" />}
                  progressColor={
                    (progressData?.followsGrowthRate || 0) < 0
                      ? "text-[#FC0000]"
                      : "text-[#0AA630]"
                  }
                  progressRate={`${
                    (progressData?.followsGrowthRate || 0) < 0 ? "-" : "+"
                  }
              ${progressData?.followsGrowthRate || 0} %`}
                  onClick={() => {}}
                  className="max-w-full md:max-w-[300px]"
                />
                <HighlightCard
                  label="Engagement"
                  value={`${
                    progressData?.engagementPercentage
                      ? progressData?.engagementPercentage.toFixed(2)
                      : 0
                  } %`}
                  icon={<Poll />}
                  progressColor={
                    (progressData?.engagementPercentageGrowthRate || 0) < 0
                      ? "text-[#FC0000]"
                      : "text-[#0AA630]"
                  }
                  progressRate={`${
                    (progressData?.engagementPercentageGrowthRate || 0) < 0
                      ? "-"
                      : "+"
                  }
              ${progressData?.engagementPercentageGrowthRate || 0} %`}
                  onClick={() => {}}
                  className="max-w-full md:max-w-[300px]"
                />
              </div>
              <div className="flex gap-6 flex-col lg:flex-row">
                <div className="shadow bg-white rounded-lg flex-1 p-6 max-w-[100%]">
                  <div className="md:min-w-[500px]">
                    <h3 className="text-lg font-semibold text-dark1">
                      Profile growth & discovery
                    </h3>
                    <span className="text-dark3 text-sm font-medium">
                      See insights on how your profile has grown and changed
                      over time
                    </span>
                    <div className="flex md:items-center md:justify-end mt-4 md:mt-0 mb-10 gap-7">
                      <div className="flex flex-col md:flex-row md:items-center md:justify-end gap-7">
                        <div className="flex flex-col md:items-end">
                          <span className="text-dark1 text-lg font-semibold">
                            {formatNumber(
                              progressData?.profileGrowth?.impressions || 0
                            )}
                          </span>
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#E4C270] rounded w-3 h-3" />
                            <span className="text-dark3 text-sm">
                              Impressions
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-col md:items-end">
                          <span className="text-dark1 text-lg font-semibold">
                            {formatNumber(
                              progressData?.profileGrowth?.reach || 0
                            )}
                          </span>
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#B7D7E4] rounded w-3 h-3" />
                            <span className="text-dark3 text-sm">Reach</span>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col md:items-end">
                        <span className="text-dark1 text-lg font-semibold">
                          {formatNumber(
                            progressData?.profileGrowth?.growthRate || 0
                          )}
                          %
                        </span>
                        <div className="flex gap-2 items-center">
                          <span className="text-dark3 text-sm">
                            Growth Rate
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="w-full h-[300px] max-w-[732px] overflow-auto">
                      <ResponsiveContainer>
                        <BarChart
                          width={500}
                          height={300}
                          data={profileGrowthData.map((item) => ({
                            ...item,
                            Reach: item.reach,
                            Impressions: item.impressions,
                            day: moment(item.day).format("MMM DD"),
                          }))}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                          barSize={8}
                        >
                          <XAxis
                            dataKey="day"
                            scale="point"
                            padding={{ left: 10, right: 10 }}
                          />
                          <YAxis />
                          <Tooltip />
                          <Bar dataKey="Reach" fill="#B7D7E4" />
                          <Bar dataKey="Impressions" fill="#E4C270" />
                          <CartesianGrid
                            strokeLinecap="square"
                            vertical={false}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
                <div className="shadow bg-white rounded-lg flex-1 lg:max-w-[348px] p-6">
                  <div className="flex items-center mb-8">
                    <div className="flex-1">
                      <h3 className="text-lg font-semibold text-dark1">
                        Post Insight
                      </h3>
                      <span>
                        {moment(currentWeekData[0]?.day).format("MMM DD")} -{" "}
                        {moment(currentWeekData[6]?.day).format("MMM DD")}
                      </span>
                    </div>
                    <div className="[&>div]:!w-fit">
                      <SelectInput
                        data={[
                          { label: "Week 1", value: 0 },
                          { label: "Week 2", value: 1 },
                          { label: "Week 3", value: 2 },
                          { label: "Week 4", value: 3 },
                        ]}
                        value={selectedWeeklyInsight}
                        showBlankOption={false}
                        onChange={(e: any) => {
                          setSelectedWeeklyInsight(e.target.value);
                        }}
                        className="!py-2 !bg-white !rounded"
                      />
                    </div>
                  </div>
                  <div className="w-full h-[200px] md:h-[300px] lg:h-[50%]">
                    <ResponsiveContainer>
                      <BarChart
                        // width={500}
                        // height={300}
                        data={currentWeekData.map((item) => ({
                          ...item,
                          Reach: item.reach,
                          day: moment(item.day).format("dd"),
                        }))}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                        barSize={8}
                      >
                        <XAxis
                          dataKey="day"
                          scale="point"
                          padding={{ left: 10, right: 10 }}
                          tick={(props) => <CustomizedLabel {...props} />}
                        />
                        <Tooltip />
                        <Bar
                          dataKey="Reach"
                          fill="#688692"
                          background={{ fill: "#F4EFE5" }}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                  <div>
                    <div className="flex justify-between items-start gap-4 border-b border-lightGray pb-4 my-4">
                      <div className="flex gap-4">
                        <ThumbUp />
                        <div className="flex flex-col">
                          <span className="text-sm font-medium">
                            Best performance
                          </span>
                          <span className="text-sm text-dark3 ">
                            {" "}
                            {moment(postInsights.max.day).format("dddd")}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col text-right">
                        <span className="text-dark1 text-sm font-medium">
                          {formatNumber(postInsights.max.reach || 0)}
                        </span>
                        {/* <span className="text-[#0AA630] text-sm font-medium">
                      +84.5 %
                    </span> */}
                      </div>
                    </div>

                    <div className="flex justify-between items-start gap-4">
                      <div className="flex gap-4">
                        <ThumbDown />
                        <div className="flex flex-col">
                          <span className="text-sm font-medium">
                            Worst performance
                          </span>
                          <span className="text-sm text-dark3 ">
                            {moment(postInsights.min.day).format("dddd")}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col text-right">
                        <span className="text-dark1 text-sm font-medium">
                          {formatNumber(postInsights.min.reach || 0)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-6 flex-wrap">
                {/* <div className="shadow bg-white rounded-lg w-full lg:w-[354px] p-6">
                  <h3 className="text-lg font-semibold text-dark1 mb-6">
                    Insights overview
                  </h3>
                  <div>
                    <div className="py-3 bg-white rounded-lg border border-lightGray gap-2">
                      <div className="px-4 border-b border-lightGray flex-1 flex items-center gap-3 pb-3">
                        <div className="w-11 h-11 rounded-full">
                          <img
                            src={
                              userInfo?.instagramProfilePic ||
                              userInfo?.IMAGES?.[0] ||
                              userImage
                            }
                            alt="profile-pic"
                            className="w-full h-full rounded-full object-fit"
                          />
                        </div>
                        <div className="flex-col gap-0.5 flex">
                          <span className="text-dark1 text-sm font-medium">
                            {progressData?.name}
                          </span>

                          <span className="text-dark3 text-sm">
                            @
                            {typeof userInfo.INSTAGRAM_HANDLE === "string"
                              ? userInfo.INSTAGRAM_HANDLE
                              : userInfo?.INSTAGRAM_HANDLE?.username}
                          </span>
                        </div>
                      </div>
                      <div className="pt-3 px-6 flex justify-between items-center">
                        <div className="flex flex-col gap-2">
                          <span className="text-dark3 font-medium text-xs">
                            POST
                          </span>
                          <span className="text-dark1 font-medium text-xs">
                            {formatNumber(progressData.totalPosts || 0)}
                          </span>
                        </div>
                        <div className="flex flex-col gap-2">
                          <span className="text-dark3 font-medium text-xs">
                            FOLLOWERS
                          </span>
                          <span className="text-dark1 font-medium text-xs">
                            {formatNumber(progressData?.totalFollowers || 0)}
                          </span>
                        </div>
                        <div className="flex flex-col gap-2">
                          <span className="text-dark3 font-medium text-xs">
                            FOLLOWING
                          </span>
                          <span className="text-dark1 font-medium text-xs">
                            {formatNumber(progressData?.totalFollows || 0)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between items-start gap-4 border-b border-lightGray pb-4 mb-4 mt-8">
                      <span className="text-sm font-medium">
                        Best performance
                      </span>
                      <div className="flex flex-col text-right">
                        <span className="text-dark1 text-sm font-medium">
                          {formatNumber(progressData.bestPerformance || 0)}
                        </span>
                        <span
                          className={`${
                            (progressData?.bestPerformanceGrowthRate || 0) < 0
                              ? "text-[#FC0000]"
                              : "text-[#0AA630]"
                          } text-sm font-medium`}
                        >
                          {(progressData?.bestPerformanceGrowthRate || 0) < 0
                            ? "-"
                            : "+"}
                          {progressData?.bestPerformanceGrowthRate || 0} %
                        </span>
                      </div>
                    </div>
                    <div className="flex justify-between items-start gap-4">
                      <span className="text-sm font-medium">
                        Content Interactions
                      </span>
                      <div className="flex flex-col text-right">
                        <span className="text-dark1 text-sm font-medium">
                          {formatNumber(progressData.contentInteractions || 0)}
                        </span>
                        <span
                          className={`${
                            (progressData?.contentInteractionsGrowthRate || 0) <
                            0
                              ? "text-[#FC0000]"
                              : "text-[#0AA630]"
                          } text-sm font-medium`}
                        >
                          {(progressData?.contentInteractionsGrowthRate || 0) <
                          0
                            ? "-"
                            : "+"}
                          {progressData?.contentInteractionsGrowthRate || 0} %
                        </span>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="shadow bg-white rounded-lg flex-1 p-6">
                  <h3 className="text-lg font-semibold text-dark1">
                    Top 3 Performing posts
                  </h3>
                  <span className="text-dark3 text-sm font-medium">
                    Quickly see which 3 posts have the best engagement from{" "}
                    {startDate.format("MMM DD")}- {endDate.format("MMM DD")}
                  </span>
                  <div className="flex flex-col gap-4 mt-4">
                    {progressData?.topPosts?.map((item) => (
                      <div
                        key={item._id}
                        className="flex flex-col md:flex-row justify-between gap-4 border-b border-lightGray pb-4"
                      >
                        <div className="flex gap-4 items-center flex-1">
                          <div className="w-16 h-16">
                            {item.mediaType === "VIDEO" ? (
                              <video
                                className="w-full h-full object-cover rounded"
                                src={item.mediaUrl}
                              />
                            ) : (
                              <img
                                src={item.mediaUrl}
                                alt="post"
                                className="w-full h-full object-cover rounded"
                              />
                            )}
                          </div>
                          <div className="flex flex-col gap-3 flex-1 max-w-[60vw] md:max-w-[250px]">
                            <div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-[60vw] md:max-w-[250px]">
                              {item.caption}
                            </div>
                            <div className="hidden md:flex gap-4 items-center">
                              <div className="flex items-center flex-1 gap-1">
                                <Favourite />
                                <span className="text-dark3 text-sm">
                                  {formatNumber(item.likesCount || 0)}
                                </span>
                              </div>
                              <div className="flex items-center flex-1 gap-1">
                                <CommentIcon />
                                <span className="text-dark3 text-sm">
                                  {formatNumber(item.commentsCount || 0)}
                                </span>
                              </div>
                              <div className="flex items-center flex-1 gap-1">
                                <Send />
                                <span className="text-dark3 text-sm">
                                  {formatNumber(item.sendsCount || 0)}
                                </span>
                              </div>
                              <div className="flex items-center flex-1 gap-1">
                                <Bookmark />
                                <span className="text-dark3 text-sm">
                                  {formatNumber(item.sharesCount || 0)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="md:hidden flex flex-1 mr-5 gap-4 items-center">
                          <div className="flex items-center gap-1 flex-1">
                            <Favourite />
                            <span className="text-dark3 text-sm">
                              {formatNumber(item.likesCount || 0)}
                            </span>
                          </div>
                          <div className="flex items-center gap-1 flex-1">
                            <CommentIcon />
                            <span className="text-dark3 text-sm">
                              {formatNumber(item.commentsCount || 0)}
                            </span>
                          </div>
                          <div className="flex items-center gap-1 flex-1">
                            <Send />
                            <span className="text-dark3 text-sm">
                              {formatNumber(item.sendsCount || 0)}
                            </span>
                          </div>
                          <div className="flex items-center gap-1 flex-1">
                            <Bookmark />
                            <span className="text-dark3 text-sm">
                              {formatNumber(item.sharesCount || 0)}
                            </span>
                          </div>
                        </div>
                        <div className="flex gap-12 items-center">
                          <div className="flex flex-col gap-3">
                            <span className="text-xs text-dark3 font-medium">
                              REACH
                            </span>
                            <span className="text-dark1 text-sm">
                              {formatNumber(item.reachCount || 0)}
                            </span>
                          </div>
                          <div className="flex flex-col gap-3 flex-1">
                            <span className="text-xs text-dark3 font-medium">
                              ENGAGEMENT
                            </span>
                            <div className="flex items-center gap-[15px]">
                              <div className="flex-1 w-[128px] h-2 overflow-hidden rounded-full bg-gray-200">
                                <div
                                  className="h-2 rounded-full bg-[#688692]"
                                  style={{
                                    width: `${item.engagementPercentage || 0}%`,
                                  }}
                                ></div>
                              </div>
                              <span className="w-10">
                                {item.engagementPercentage
                                  ? item.engagementPercentage.toFixed(0)
                                  : 0}
                                %
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="relative">
            <img src={progressImage} alt="progress" className="w-full" />
            <img
              src={progressBackground}
              alt="progress"
              className="absolute bottom-0 left-0 w-full"
            />
          </div>
        )}
      </div>
      {fetchingPosts && <LoadingScreen />}

      {instagramConnectModal && (
        <ConnectInstagram
          open={instagramConnectModal}
          onClose={handleInstagramModalClose}
          handleRefresh={handleInstagramConnectRefresh}
          data={user?.userInfo || {}}
          handleConsent={handleConfirmConsent}
        />
      )}
    </div>
  );
}
