// create image of color with 1080 x 1080 px dimensions

import { useEffect, useState } from "react";
import { Modal } from "../../../components/Modal";
import { ReactComponent as Download } from "../../../assets/document-download.svg";

// add button to download image
export function BrandingColorImageModal({
  color,
  open,
  onClose,
}: {
  color: string;
  open: boolean;
  onClose: () => void;
}) {
  const [image, setImage] = useState<string | null>(null);
  useEffect(() => {
    if (!color) return;
    // create image of color with 1080 x 1080 px dimensions
    const canvas = document.createElement("canvas");
    canvas.width = 1080;
    canvas.height = 1080;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, 1080, 1080);
    const dataURL = canvas.toDataURL("image/png");
    setImage(dataURL);
  }, [color]);

  const downloadImage = () => {
    if (!image) return;
    const link = document.createElement("a");
    link.href = image;
    link.download = "color.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal open={open} onClose={onClose} contentClassName="!w-[18.5rem]">
      <div className="relative">
        {image && (
          <div className="w-64 h-64">
            <img
              src={image}
              alt="color"
              className="w-full h-full object-cover"
            />
            <button
              className="absolute top-[40%] left-[40%] bg-black/20 flex items-center justify-center rounded-full w-12 h-12"
              onClick={downloadImage}
            >
              <Download className="w-6 h-6 [&_path]:stroke-primary" />
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
}
