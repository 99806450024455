import { useEffect, useState } from "react";
import { Modal } from "../../components/Modal";
import { CancelSubscription } from "./CancelSubscription";
import { api } from "../../services/api";
import { getUser } from "../../services/utils";
import { ShowToast } from "../../services/toast";
import { Loading } from "../../components/Loading/Loading";

export function SubscriptionView({ open, onClose }) {
  const user = getUser();
  const userId = user._id;
  const [subscription, setSubscription] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const fetchSubscription = async (userId: string) => {
    try {
      setLoading(true);
      const response = await api.getSubscription(userId);
      setSubscription(response.data[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ShowToast({
        type: "error",
        message: "Failed to fetch subscription",
      });
    }
  };

  useEffect(() => {
    if (open && userId) {
      fetchSubscription(userId);
    }
  }, [open, userId]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      contentClassName="!max-w-[500px] w-full !p-6"
      closeButtonStyle="right-8 top-8 [&>svg_path]:stroke-black"
    >
      <h1 className="text-black text-lg font-semibold mb-6 leading-normal">
        Subscription
      </h1>
      {loading && (
        <div className="flex justify-center items-center [&>svg]:h-8 [&>svg]:w-8">
          <Loading />
        </div>
      )}
      {subscription && (
        <>
          <div className="flex flex-col gap-6 mb-6 py-4 px-2 rounded-lg shadow bg-ultraLightGray">
            <p className="text-base text-dark1">
              Subscription Type:{" "}
              {subscription.planType === "M" ? "Monthly" : "Yearly"}
            </p>
            <p className="text-base text-dark1">
              Customer Email: {subscription.customerEmail}
            </p>
            <p className="text-base text-dark1">
              Subscription Price: ${subscription.price || "99"}
            </p>
          </div>
          <div className="flex justify-end">
            <CancelSubscription subscription={subscription} userId={userId} />
          </div>
        </>
      )}
    </Modal>
  );
}
