import React, {useEffect, useRef, useState} from 'react'
import {Sketch} from '@uiw/react-color'

export const ColorInput = ({
                             onChange,
                             value,
                             title,
                           }: { onChange: (color: string) => void; value: string, title?: string }) => {
  const [open, setOpen] = useState(false)
  const ref = useRef(null)

  const handleChange = (color) => {
    onChange(color.hex)
  }

  // implement close on outside click
  useEffect(() => {
    // detect click outside
    document.addEventListener('click', (e) => {
      //@ts-expect-error
      if (ref.current && !ref.current.contains(e.target)) {
        setOpen(false)
      }
    })
  }, [])

  return (
      <div className="flex flex-1 relative ss:w-1/2 md:w-full justify-center" ref={ref}>
        <div>
        {title ? (<div
            className="text-black text-center mb-2 ss:text-xs md:text-lg font-semibold font-['Inter'] leading-normal">
          {title}
        </div>) : null}
        <button
            className="w-full ss:w-28 ss:h-12 md:w-48 md:h-20 relative z-[1]"
            onClick={() => {
              setOpen(!open)
            }}
        >
          <div className={'block'}>
            <div
                className="flex w-full ss:w-28 ss:h-12 md:w-48 md:h-20 rounded-lg items-center justify-center"
                style={{backgroundColor: value}}
            >
              <span
                  className="text-white p-3 ss:text-sm md:text-lg font-semibold font-['Inter'] leading-normal">
                {value}
              </span>
            </div>
          </div>
        </button>
        {open && (
            <div className="absolute z-[2]">
              <Sketch color={value} onChange={handleChange} disableAlpha={true}/>
            </div>
        )}
        </div>
      </div>
  )
}
