import { Header } from "./Header"

export function Layout({children}: {
    children: React.ReactNode
}) {
    return (
        <div className="h-[100vh] flex flex-col bg-ultraLightGray">
            <Header />
            <div className="flex-1 flex items-center justify-center">
                {children}
            </div>
        </div>
    )
}