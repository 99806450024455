export enum OnboardingStepsEnum {
  WELCOME_COACH = 1,
  BRAND_LAF = 2,
  LAST_STEP = 3,
}

export const stepsObject = {
  WELCOME_COACH: 1,
  BRAND_LAF: 2,
  LAST_STEP: 3,
}


export enum OnboardingStepsNamesEnum {
  WELCOME_COACH = 'WELCOME_COACH',
  BRAND_LAF = 'BRAND_LAF',
  LAST_STEP = 'LAST_STEP',
}

export interface IOnboardingStep {
  title:       string;
  description: string;
  questions:   IOnboardingStepQuestion[];
  code:        string;
  isOldUser?: boolean
}

export interface IBrandStyleColors {
  primary:        IBrandStyleColorsItem;
  lightSecondary: IBrandStyleColorsItem;
  darkSecondary:  IBrandStyleColorsItem;
  accent:         IBrandStyleColorsItem;
  neutral:        IBrandStyleColorsItem;
}

export interface IBrandStyleColorsItem {
  code:   string;
  reason?: string;
}

export interface IBrandStyleTypographyItem {
  "font-family": string;
  reason?:        string;
}


export interface IBrandStyleTypography {
  h1: IBrandStyleTypographyItem;
  h2: IBrandStyleTypographyItem;
}

export type IOnboardingStepAnswer = string | string[] | IBrandStyleTypography | IBrandStyleColors;

export interface IOnboardingStepQuestion {
  placeholder: string;
  type:        string;
  isActive:    boolean;
  fieldName:   string;
  code:        string;
  answer:      IOnboardingStepAnswer;
  keywords?:   string[];
}

export interface IOnboardingStepMap extends Omit<IOnboardingStep, 'questions'>{
  questions: Record<string, IOnboardingStepQuestion>
}

export interface IBrandStyle {
  brandStyleColors: IBrandStyleColors
  brandStyleTypography: IBrandStyleTypography
}