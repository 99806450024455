export function ProgressBar({
  progress,
  total,
}: {
  progress: number;
  total: number;
}) {
  const progressPercent = (100 * progress) / total;
  return (
    <div className="flex items-center gap-4">
      <div className="text-neutral-800 text-sm">
        {progress} of {total}
      </div>
      <div className="flex-1 h-2 overflow-hidden rounded-full bg-gray-200">
        <div
          className="h-2 rounded-full bg-primary"
          style={{ width: `${progressPercent}%` }}
        ></div>
      </div>
    </div>
  );
}
