import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { TextInput } from "../../components/TextInput";
import { Layout } from "./components/Layout";
import React, { useEffect } from "react";
import {
  setToken,
  setUser as setUserInfo,
  getUser,
  setUser,
  validateEmail,
} from "../../services/utils";
import { api } from "../../services/api";
import { Separator } from "./components/Separator";
import { SocialLogin } from "./SocialLogin";
import { ReactComponent as AlertIcon } from "../../assets/Alert.svg";
import { Password } from "./components/Password";
import { ConfirmEmail } from "./ConfirmEmail";
import { pageView } from "../../services/mixpanel";
import { useApp } from "../../services/app-context";

export function Login() {
  const { refetchPaymentInfo } = useApp() as any;
  const location = useLocation();
  const navigate = useNavigate();
  const [showOnboarding, setShowOnboarding] = React.useState(false);
  const [showConfirmView, setShowConfirmView] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>("");
  const [emailError, setEmailError] = React.useState<string>("");

  const sendVerificationCode = async (userId) => {
    if (!userId) return;
    const response = await api.initEmailVerification(userId);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // get the form data
    const formData = new FormData(e.currentTarget);
    // convert the form data to an object
    const data = Object.fromEntries(formData);
    // log the results
    setLoading(true);
    try {
      const response = await api.login({
        email: data.email,
        password: data.password,
      });
      if (response.status === 401) {
        setError("Invalid credentials");
        setLoading(false);
        return;
      }
      const result = response.data;
      if (result.accessToken) {
        setToken(result.accessToken);
        setUserInfo({ ...result });
        refetchPaymentInfo(result._id);
        if (!result.isEmailVerified) {
          setShowConfirmView(true);
          await sendVerificationCode(result._id);
          setLoading(false);
          return;
        }
        if (result.isOnboardingCompleted) {
          if (location.state?.from) {
            navigate(location.state.from);
          } else {
            navigate("/dashboard");
          }
        } else {
          // setShowOnboarding(true);
          navigate("/onboarding");
        }
        // const subscriptionResponse = await api.getSubscription(result._id);
        // if (
        //   subscriptionResponse?.data?.length > 0 &&
        //   subscriptionResponse.data[0]?.status === "Paid"
        // ) {
        //   if (result.isOnboardingCompleted) {
        //     if (location.state?.from) {
        //       navigate(location.state.from);
        //     } else {
        //       navigate("/dashboard");
        //     }
        //   } else {
        //     // setShowOnboarding(true);
        //     navigate("/onboarding");
        //   }
        // } else {
        // navigate to subscription page
        // navigate("/subscription");
        // }
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 401) {
        setError("Invalid email or password");
        return;
      }
      setError("Something went wrong");
      return false;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    const isValidEmail = validateEmail(data?.email?.toString());

    if (isValidEmail) {
      setEmailError("");
    }

    if (data.email && data.password) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleValidateEmail = (e: any) => {
    const value = e.target.value;
    const isValidEmail = validateEmail(value);

    if (!isValidEmail) {
      setEmailError("Please enter a valid email");
    } else {
      setEmailError("");
    }
  };

  // Temp
  const responseFacebook = async (response: any) => {};

  const handleSocialSuccess = (response: any) => {
    // setShowOnboarding(true);
    navigate("/onboarding");
  };

  const handleOnConfirmEmail = async () => {
    const user = getUser();

    setShowConfirmView(false);
    setUser({ ...user, isEmailVerified: true });
    // navigate("/subscription");

    // TODO: once confirm email user should be redirected to subscription page
    if (user.isOnboardingCompleted) {
      if (location.state?.from) {
        navigate(location.state.from);
      } else {
        navigate("/dashboard");
      }
    } else {
      // setShowOnboarding(true);
      navigate("/onboarding");
    }
  };

  useEffect(() => {
    pageView();
  }, []);

  if (showConfirmView) {
    return (
      <ConfirmEmail
        onSuccess={handleOnConfirmEmail}
        onClose={() => setShowConfirmView(false)}
      />
    );
  }

  return (
    <Layout>
      <div className="flex flex-col w-full md:w-[438px] max-h-[calc(100vh_-_80px)] overflow-auto">
        <div className="rounded-lg p-6 bg-white flex flex-col mx-3">
          <div className="text-dark1 text-2xl font-semibold">Sign in</div>
          <form
            onSubmit={handleSubmit}
            className="flex-1"
            onChange={handleChange}
          >
            {error && (
              <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                <AlertIcon /> {error}
              </div>
            )}
            <div className="my-6 flex flex-col gap-5">
              <div className="flex flex-col gap-1.5">
                <span className="text-black text-xs font-medium">Email</span>
                <TextInput
                  placeholder="Your email"
                  name="email"
                  className="w-full !p-[14px]"
                  onBlur={handleValidateEmail}
                />
                {emailError && (
                  <span className="text-red-500 text-xs font-medium">
                    {emailError}
                  </span>
                )}
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-black text-xs font-medium">Password</span>
                <Password
                  placeholder="Your password"
                  name="password"
                  className="!p-[14px]"
                  type="password"
                />
              </div>
              <Link to="/forgot-password" className="w-fit self-end">
                <div className="text-black border-b pb-1 border-dashed border-primary font-medium text-right">
                  Forgot password?
                </div>
              </Link>
            </div>
            <Button
              loading={loading}
              disabled={disabled}
              className="w-full"
              variant="primary"
            >
              Sign in
            </Button>
          </form>
          {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
          <Separator />
          <SocialLogin
            responseFacebook={responseFacebook}
            onSuccess={handleSocialSuccess}
          />
        </div>
        <div className="flex text-sm text-dark2 gap-1.5 mt-4 justify-center">
          Don't have an account?
          <Link
            to="/register"
            className="text-black border-b pb-1 border-dashed border-primary font-medium"
          >
            Sign up
          </Link>
        </div>
        <div className="flex text-sm text-dark2 gap-3 pt-2 justify-evenly">
          <a
            href="https://guidely.com/terms-of-use"
            target="_blank"
            className="text-blue-500"
            rel="noreferrer"
          >
            Terms of Use
          </a>
          <a
            href="https://guidely.com/privacy-policy"
            target="_blank"
            className="text-blue-500"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </Layout>
  );
}
