import { useEffect } from "react";
import { useLocation } from "react-router-dom";

declare global {
  interface Window {
    _hsq: any[];
  }
}

const _hsq = window._hsq || [];

// a page listener component that will be used to track page views

export const AnalyticsListener = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const url = window.location.href;
    _hsq.push(["setPath", url]);
    _hsq.push(["trackPageView"]);
  }, [location]);

  return children;
};
