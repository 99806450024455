import { Layout } from "./components/Layout";
import { Button } from "../../components/Button";
import { TextInput } from "../../components/TextInput";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { api } from "../../services/api";
import { ForgotPasswordSuccess } from "./components/ForgotPasswordSuccess";
import { ShowToast } from "../../services/toast";
import { pageView } from "../../services/mixpanel";

export function ForgotPassword() {
  const [loading, setLoading] = React.useState(false);
  const [isPasswordSame, setIsPasswordSame] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [success, setSuccess] = React.useState(false);

  const handleSendEmail = async () => {
    if (email) {
      try {
        setLoading(true);
        const response = await api.forgotPassword({
          email,
          resetPasswordUrl: `${window.location.origin}/reset-password`,
        });
        if ([200, 201].includes(response.status)) {
          setSuccess(true);
        }
        setLoading(false);
      } catch (error) {
        ShowToast({
          type: "error",
          message: "Failed to send email",
        });
        setLoading(false);
      }
    }
  };

  // check if email is valid using regex
  const disabled = !email || !email.match(/^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/g);

  useEffect(() => {
    pageView();
  }, []);

  if (success) {
    return (
      <Layout>
        <div className="flex flex-col w-full md:w-[438px] max-h-[calc(100vh_-_100px)] overflow-auto">
          <div className="rounded-lg p-6 bg-white flex flex-col mx-3">
            <ForgotPasswordSuccess />
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="flex flex-col w-full md:w-[438px] max-h-[calc(100vh_-_100px)] overflow-auto">
        <div className="rounded-lg p-6 bg-white flex flex-col mx-3">
          <div className="text-dark1 text-2xl font-semibold leading-loose">
            Forgot password?
          </div>
          <span className="text-dark2 text-sm font-normal leading-normal ">
            No worriest! Just enter your email and we’ll send you a reset
            password link.
          </span>
          <div className="my-6 flex flex-col gap-5">
            <div className="flex flex-col gap-1.5">
              <span className="text-black text-xs font-medium">Email</span>
              <TextInput
                placeholder="Your email"
                className="w-full !p-[14px]"
                name="email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
          </div>
          <Button
            disabled={disabled}
            loading={loading}
            className="w-full"
            variant="primary"
            onClick={handleSendEmail}
          >
            Send Recovery Email
          </Button>
        </div>
        <div className="flex text-sm text-dark2 gap-1.5 mt-4 justify-center">
          Just remember?
          <Link
            to="/login"
            className="text-black border-b pb-1 border-dashed border-primary font-medium"
          >
            Sign in
          </Link>
        </div>
      </div>
    </Layout>
  );
}
