import { Button } from "../../../components/Button";
import { ReactComponent as GetStartedIcon } from "../../../assets/get-started-icon.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { PaymentPopup } from "./PaymentPopup";

export function PaymentBox({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const handleSubscribe = () => {
    // navigate("/subscription", {
    //   state: { from: `${location.pathname}${location.search}` },
    // });
    setShowPopup(true);
  };
  return (
    <div>
      <div className="bg-white rounded-lg border border-[#E4C270] py-6 mb-6 px-5 flex flex-col md:flex-row justify-between gap-12">
        <div className="flex flex-col md:flex-row flex-1 gap-6 items-center md:items-start ">
          <GetStartedIcon className="min-w-[64px] min-h-[64px] w-16 h-16" />
          <div className="flex flex-col gap-3">
            <span className="text-dark1 text-base font-medium">{title}</span>
            <p className="text-dark2 text-sm">{description}</p>
          </div>
        </div>
        <div className="flex items-center">
          <Button
            variant="primary"
            className="w-full md:w-auto"
            onClick={handleSubscribe}
          >
            Start Trial
          </Button>
        </div>
      </div>
      {showPopup && (
        <PaymentPopup
          isSubscriptionActive={false}
          open={showPopup}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
}
