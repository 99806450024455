import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { initMixPanel } from "./services/mixpanel";
import { initGA } from "./services/ga";
import TagManager from "react-gtm-module";
import { GTM_ID, PRODUCTION_HOST } from "./constant";

const tagManagerArgs = {
  gtmId: GTM_ID,
  events: {
    signUp: "Sign Up",
    subscription: "Subscription",
    onboardingCompleted: "Onboarding Completed",
  },
};

initMixPanel();
if (window.location.hostname === PRODUCTION_HOST) {
  TagManager.initialize(tagManagerArgs);
  initGA();
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
