import React, {useState} from 'react'

export type KeywordsParams = {
  keywords: string[]
  onSelected: (keywords: string[]) => void
  selectedKeywords?: string[]
}

export function Keywords(params: KeywordsParams) {
  const [allAvailableKeywords] = useState(params.keywords)
  const [selectedKeywords, setSelectedKeywords] = useState(
      params.selectedKeywords || [])
  const toggleSelected = (item: string) => {
    let selected: Array<string> = []
    if (selectedKeywords.includes(item)) {
      selected = selectedKeywords.filter((i) => i !== item)
      setSelectedKeywords(selected)
    } else {
      if (selectedKeywords.length < 5) {
        selected = [...selectedKeywords, item]
        setSelectedKeywords(selected)
      }
    }
    params.onSelected(selected)
  }

  return <div
      className="flex flex-wrap flex-1 justify-center md:p-5 grid-cols-[repeat(auto-fill,minmax(120px,full))] 2xl:grid-cols-10 xl:grid-cols-8 lg:grid-cols-6 md:grid-cols-5 xs:grid-cols-4 ss:grid-cols-3 ss:gap-2 xs:gap-2 sm:grid-cols-4 gap-5 md:bg-ultraLightGray">
    {allAvailableKeywords.map((item, i) => (
        <div className="flex">
          <button
              onClick={() => toggleSelected(item)}
              className={`flex-1 w-28 text-center px-3.5 py-2 bg-white rounded border xs:w-20 xs:px-0 sm:w-32 sm:px-3.5 ss:w-20 ss:px-1 ${
                  selectedKeywords.includes(item) ?
                      'border-primary' :
                      'border-lightGray'
              }`}
              disabled={selectedKeywords.length === 5 &&
              !selectedKeywords.includes(item)}
              key={`${item}-${i}`}
          >
            <div
                className={`${
                    selectedKeywords.includes(item) ?
                        'text-black' :
                        'text-grey1'
                } text-sm font-medium leading-normal ss:text-ss xs:text-xs sm:text-sm md:text-base`}
            >
              {item}
            </div>
          </button>
        </div>
    ))}
  </div>

}