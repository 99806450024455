import { useState } from "react";
import { Button } from "../../components/Button";
import { Modal } from "../../components/Modal";
import { ShowToast } from "../../services/toast";
import { api } from "../../services/api";
import { useNavigate } from "react-router-dom";

export function CancelSubscription({
  subscription,
  userId,
}: {
  subscription: any;
  userId: string;
}) {
  const navigate = useNavigate();
  const [subscriptionConfirmation, setSubscriptionConfirmation] =
    useState(false);
  const [cancellingSubscription, setCancellingSubscription] = useState(false);
  const handleCancelSubscription = () => {
    setSubscriptionConfirmation(true);
  };

  const closeSubscriptionConfirmation = () => {
    setSubscriptionConfirmation(false);
  };

  const onConfirm = async () => {
    try {
      const subscriptionId = subscription.subscriptionId;
      setCancellingSubscription(true);
      const response = await api.cancelSubscription(userId, subscriptionId);
      setCancellingSubscription(false);
      if (response.status === 200) {
        ShowToast({
          type: "success",
          message: "Subscription cancelled successfully",
        });
        navigate("/dashboard");
        setSubscriptionConfirmation(false);
      }
    } catch (err) {
      ShowToast({
        type: "error",
        message: "Failed to cancel subscription",
      });
      setCancellingSubscription(false);
    }
  };

  return (
    <div>
      <Button variant="primary" onClick={handleCancelSubscription}>
        Cancel Subscription
      </Button>
      <Modal
        open={subscriptionConfirmation}
        onClose={closeSubscriptionConfirmation}
        contentClassName="!max-w-[400px]"
        showCloseButton={false}
      >
        <div className="text-center">
          <div className="text-center text-[#000] text-2xl font-semibold leading-[34px]">
            Are you sure you want to cancel your subscription?
          </div>
          <div className="mt-3.5 leading-[18px] text-center text-black text-sm font-normal">
            Please know that by cancelling your subscription, you will lose
            access to all of your scheduled posts & this platform. Do you still
            wish to proceed?
          </div>
          <div className="flex gap-2.5 justify-center mt-6 flex-wrap">
            <Button
              disabled={cancellingSubscription}
              variant="outline"
              onClick={closeSubscriptionConfirmation}
              className="w-full md:w-auto"
            >
              Cancel
            </Button>
            <Button
              loading={cancellingSubscription}
              onClick={onConfirm}
              variant="primary"
              className="w-full md:w-auto"
            >
              Yes, Cancel Subscription
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
