import { Button } from "../../../components/Button";
import { ReactComponent as GetStartedIcon } from "../../../assets/get-started-icon.svg";
import { ReactComponent as ThumbUp } from "../../../assets/thumb-up.svg";
import { ReactComponent as ThumbDown } from "../../../assets/thumb-down.svg";
import { Feedback } from "./Feedback";
import { useState } from "react";
import { api } from "../../../services/api";
import { getUser } from "../../../services/utils";
import { ShowToast } from "../../../services/toast";
import { PaymentBox } from "./PaymentBox";

export function GetStarted({
  open,
  onClose,
  confirmingConsent,
  onInstagramConnect,
  instagramConnected,
  isSubscriptionActive,
}: {
  open: boolean;
  onClose: () => void;
  confirmingConsent: boolean;
  onInstagramConnect: () => void;
  instagramConnected: boolean;
  isSubscriptionActive: boolean;
}) {
  const user = getUser();
  const userId = user._id;
  const [showFeedback, setShowFeedback] = useState<
    { title: string; message?: string } | boolean
  >(false);
  const [loading, setLoading] = useState(false);
  const [upFeedbackLoading, setUpFeedbackLoading] = useState(false);

  const handleDown = async () => {
    if (!userId) return;
    try {
      setLoading(true);
      const response = await api.contentFeedback(userId, {
        isDown: true,
      });
      if ([200, 201].includes(response.status)) {
        setShowFeedback({
          message: `A member of our team will be reaching out to you shortly to provide a
          solution to any issues you're experiencing`,
          title: "Thank you for your feedback!",
        });
      }
    } catch (error) {
      ShowToast({
        type: "error",
        message: "Failed to submit feedback",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUp = async () => {
    if (!userId) return;
    try {
      setUpFeedbackLoading(true);
      const response = await api.contentFeedback(userId, {
        isDown: false,
      });
      if ([200, 201].includes(response.status)) {
        setShowFeedback({
          title: `Thank you for your feedback!`,
          message: "Glad to hear you are enjoying Guidely.",
        });
      }
    } catch (error) {
      ShowToast({
        type: "error",
        message: "Failed to submit feedback",
      });
    } finally {
      setUpFeedbackLoading(false);
    }
  };

  const closeFeedback = () => {
    setShowFeedback(false);
  };

  if (!isSubscriptionActive) {
    return (
      <div>
        <PaymentBox
          title="Start Your 30-Day Free Trial"
          description="Get access to a full month's worth of personalized graphics and captions. Edit, discard, or create new posts at any time. 
          Once you're satisfied, set everything to automatically upload to Instagram, freeing you up to concentrate on your clients!"
        />
      </div>
    );
  }

  if (instagramConnected || instagramConnected === undefined) {
    return (
      <div className="bg-white rounded-lg border border-[#E4C270] py-6 mb-6 px-5 flex flex-col md:flex-row justify-between gap-12">
        <div className="flex flex-col md:flex-row flex-1 gap-6 items-center md:items-start ">
          <GetStartedIcon className="min-w-[64px] min-h-[64px] w-16 h-16" />
          <div className="flex flex-col gap-3">
            <span className="text-dark1 text-base font-medium">
              Welcome to your future feed
            </span>
            <p className="text-dark2 text-sm">
              Check out your unique drafts for the upcoming month, tailored with
              your colors and fonts. Share your thoughts!
            </p>
          </div>
        </div>
        <div className="flex gap-2 items-center justify-center">
          <Button
            loading={upFeedbackLoading}
            disabled={upFeedbackLoading}
            onClick={handleUp}
            variant="link"
          >
            <ThumbUp className="w-8 h-8" />
          </Button>
          <Button
            loading={loading}
            disabled={loading}
            onClick={handleDown}
            variant="link"
          >
            <ThumbDown className="w-8 h-8" />
          </Button>
        </div>
        <Feedback
          open={showFeedback}
          onClose={closeFeedback}
          data={showFeedback}
        />
      </div>
    );
  }

  return (
    <div>
      <div className="bg-white rounded-lg border border-[#E4C270] py-6 mb-6 px-5 flex flex-col md:flex-row justify-between gap-12">
        <div className="flex flex-col md:flex-row flex-1 gap-6 items-center md:items-start ">
          <GetStartedIcon className="min-w-[64px] min-h-[64px] w-16 h-16" />
          <div className="flex flex-col gap-3">
            <span className="text-dark1 text-base font-medium">
              Welcome to your future feed
            </span>
            <p className="text-dark2 text-sm">
              Allow Guidely to post to Instagram on your behalf by connecting
              your IG account. This will schedule shown posts for publication.{" "}
              <a
                href="https://coaches.guidely.com/knowledge-base/using-an-instagram-business-account"
                className="text-primary font-semibold"
                target="_blank"
                rel="noreferrer"
              >
                Learn More
              </a>
            </p>
          </div>
        </div>
        <div className="flex items-center">
          <Button
            onClick={onInstagramConnect}
            loading={confirmingConsent}
            disabled={confirmingConsent}
            variant="primary"
            className="w-full md:w-auto"
          >
            Approve & Connect
          </Button>
        </div>
      </div>
      <div className="flex mb-5 items-center gap-5 justify-end">
        <span className="text-sm">How do you like your future feed?</span>
        <div className="flex gap-2 items-center justify-center">
          <Button
            loading={upFeedbackLoading}
            disabled={upFeedbackLoading}
            onClick={handleUp}
            variant="link"
          >
            <ThumbUp className="w-8 h-8" />
          </Button>
          <Button
            loading={loading}
            disabled={loading}
            onClick={handleDown}
            variant="link"
          >
            <ThumbDown className="w-8 h-8" />
          </Button>
        </div>
      </div>
      <Feedback
        open={showFeedback}
        onClose={closeFeedback}
        data={showFeedback}
      />
    </div>
  );
}
