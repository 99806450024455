import React, {
  createContext,
  useCallback, useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {getUser} from './utils'
import {api} from './api'
import {Loading} from '../components/Loading/Loading'

export const AppContext = createContext<Record<any, any> | null>(null)

const isSubscribed = (paymentInfo) => {
  if (paymentInfo?.length > 0) {
    if (paymentInfo[0]?.status === 'Paid') {
      return true
    }
    // if status is Cancelled then check if createdAt is within 30 days of cancelledAt
    if (paymentInfo[0]?.status === 'Cancelled') {
      return false
    }
  }
  return false
}

export function AppProvider({children}) {
  const [paymentInfo, setPaymentInfo] = useState<any[] | null>(null)
  // dashboard data
  const [dashboard, setDashboard] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [isSubscriptionActive, setSubscription] = useState(false)

  const [isFetchingPaymentInfo, setIsFetchingPaymentInfo] =
      useState(false)
  const user = getUser()

  const fetchPaymentInfo = useCallback(
      async (userId, isRefetch, isFetchingPaymentInfo,
             paymentInfo?: Array<any> | null) => {
        try {
          if (isRefetch || !paymentInfo) {
            setIsFetchingPaymentInfo(true)
            const subscriptionResponse = await api.getSubscription(userId)
            if (Array.isArray(subscriptionResponse?.data)) {
              setPaymentInfo(subscriptionResponse.data)
              setSubscription(isSubscribed(subscriptionResponse.data))
            }
            setIsFetchingPaymentInfo(false)
          }
        } catch (e) {
          console.error(e)
          setIsFetchingPaymentInfo(false)
        }
      }, [])

  const refetchPaymentInfo = useCallback(async (userId = user?.id) => {
    if (userId && !isFetchingPaymentInfo) {
      await fetchPaymentInfo(userId, true, isFetchingPaymentInfo)
    }
  }, [user?.id, fetchPaymentInfo, isFetchingPaymentInfo])

  useEffect(() => {
    if (user?._id && !paymentInfo && !isFetchingPaymentInfo) {
      console.log('fetchPaymentInfo:', isFetchingPaymentInfo)
      fetchPaymentInfo(user._id, false, isFetchingPaymentInfo, paymentInfo)
    }
  }, [user?._id, fetchPaymentInfo, isFetchingPaymentInfo, paymentInfo])

  const fetchDashboardData = async (
      userId: string,
      shouldRefreshWholePage?: boolean,
  ) => {
    shouldRefreshWholePage !== false && setLoading(true)
    try {
      const res = await api.getDashboard(userId)
      setDashboard(res)
    } catch (error) {
    } finally {
      if (shouldRefreshWholePage !== false) {
        setLoading(false)
      }
    }
  }

  const values = useMemo(() => ({
    paymentInfo,
    isSubscriptionActive,
    refetchPaymentInfo,
    setPaymentInfo,
    dashboard,
    setDashboard,
    loading,
    setLoading,
    fetchDashboardData,
  }), [
    paymentInfo,
    isSubscriptionActive,
    dashboard,
    loading,
    refetchPaymentInfo,
  ])

  if (isFetchingPaymentInfo) {
    return <div><Loading/></div>
  }
  return <AppContext.Provider value={{...values}}>{children}</AppContext.Provider>
}

export function useApp() {
  const context = useContext(AppContext)
  if (context === undefined) {
    throw new Error('useApp must be used within a AppProvider')
  }
  return context
}
