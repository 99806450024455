import { Button } from "../../../components/Button";
import { ReactComponent as GetStartedIcon } from "../../../assets/get-started-icon.svg";

export function GetStarted({
  onInstagramConnect,
}: {
  onInstagramConnect: () => void;
}) {
  return (
    <div className="bg-white rounded-lg border border-[#E4C270] py-6 mb-6 px-5 flex flex-col md:flex-row justify-between gap-12">
      <div className="flex flex-col md:flex-row flex-1 gap-6 items-center md:items-start ">
        <GetStartedIcon className="min-w-[64px] min-h-[64px] w-16 h-16" />
        <div className="flex flex-col gap-3">
          <span className="text-dark1 text-base font-medium">
            Unlock this feature
          </span>
          <p className="text-dark2 text-sm">
            To access your growth and analytics and auto-post, grant Guidely
            access to your Instagram account
          </p>
        </div>
      </div>
      <div className="flex items-center">
        <Button
          onClick={onInstagramConnect}
          variant="primary"
          className="w-full md:w-auto"
        >
          Approve & Connect
        </Button>
      </div>
    </div>
  );
}
