import { useState } from "react";
import { MANAGE_STRIPE_URL } from "../../env";
import { SubscriptionView } from "../payment/SubscriptionView";
import { Button } from "../../components/Button";
import { AutomaticPosting } from "./components/AutomaticPosting";
import { useOutletContext } from "react-router-dom";
import { getUser } from "../../services/utils";
import { ShowToast } from "../../services/toast";
import { useUser } from "../profile/context/user-context";
import { ConnectInstagram } from "../onboarding/components/ConnectInstagram";
import { api } from "../../services/api";
import { Loading } from "../../components/Loading/Loading";

export function SettingsPage() {
  const [showSubscription, setShowSubscription] = useState(false);
  const [autoPostingModal, setAutoPostingModal] = useState(false);
  const [instagramConnectModal, setInstagramConnectModal] = useState(false);
  const [confirmingConsent, setConfirmingConsent] = useState(false);
  const { instagramConnected, fetchInstagramConnected } = useOutletContext<{
    instagramConnected: boolean;
    fetchInstagramConnected: (userId: string) => void;
  }>();
  const { _id, ...rest } = getUser();
  const user = useUser() as any;
  const isPaused = !user?.userInfo?.canAutoSchedulePost;
  const handleInstagramModalClose = () => {
    setInstagramConnectModal(false);
    user.fetchUser(_id);
  };

  const handleConfirmConsent = async (autoPost: boolean) => {
    try {
      setConfirmingConsent(true);
      const response = await api.saveUserProfile(_id, {
        canAutoSchedulePost: autoPost,
      });
      setConfirmingConsent(false);
      if ([200, 201].includes(response.status)) {
        await user.fetchUser(_id);
        setAutoPostingModal(false);
      } else {
        ShowToast({
          type: "error",
          message: "Error confirming consent",
        });
      }
    } catch (error) {
      setConfirmingConsent(false);
      ShowToast({
        type: "error",
        message: "Error confirming consent",
      });
    }
  };

  const handleInstagramConnectRefresh = () => {
    fetchInstagramConnected(_id);
  };

  const showSubscriptionModal = () => {
    window.open(MANAGE_STRIPE_URL, "_blank");
    // setShowSubscription(true);
  };

  const closeSubscriptionModal = () => {
    setShowSubscription(false);
  };
  return (
    <div className="relative bg-ultraLightGray h-[calc(100vh_-_70px)] p-4 md:p-6">
      <div className="max-w-[1250px] mx-auto">
        <h1 className="text-[#202020] text-4xl font-semibold leading-10  mb-8">
          Settings
        </h1>
        {instagramConnected === undefined ? (
          <div className="bg-white rounded-lg border px-7 py-20 flex justify-center items-center">
            <Loading />
          </div>
        ) : (
          <>
            <div className="bg-white rounded-lg border px-7">
              <div className="flex items-center py-11 gap-8 justify-between border-b border-b-[#DEDEDE]">
                <div>
                  <div className="text-[#202020] text-base font-medium font-['Inter'] leading-tight">
                    Automatic Posting
                  </div>
                  <div className="text-sm text-dark2 mt-[9px]">
                    {instagramConnected
                      ? isPaused
                        ? `Automatic posting is paused. Ready to post automatically again? Turn it on to resume.`
                        : `Your posts are automatically being scheduled to Instagram. Want to stop? You can pause anytime.`
                      : `Disabled - Please approve your content and connect your Instagram account to enable automatic posting.`}
                  </div>
                </div>
                {instagramConnected ? (
                  <Button
                    onClick={() => {
                      setAutoPostingModal(true);
                    }}
                    variant="primary"
                    className="!px-7 !py-2 !font-medium"
                  >
                    {isPaused ? "Resume" : "Pause"}
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setInstagramConnectModal(true);
                    }}
                    variant="primary"
                    className="!px-7 !py-2 !font-medium"
                  >
                    Connect
                  </Button>
                )}
              </div>
              <div className="flex items-center py-11 gap-8 justify-between">
                <div>
                  <div className="text-[#202020] text-base font-medium font-['Inter'] leading-tight">
                    Manage Subscription
                  </div>
                  <div className="text-sm text-dark2 mt-[9px]">
                    Effortlessly manage your subscriptions, staying organized
                    with easy updates and adjustments.
                  </div>
                </div>
                <Button
                  onClick={() => showSubscriptionModal()}
                  variant="primary"
                  className="!px-7 !py-2 !font-medium"
                >
                  Manage subscription
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
      {showSubscription && (
        <SubscriptionView
          open={showSubscription}
          onClose={closeSubscriptionModal}
        />
      )}
      {autoPostingModal && (
        <AutomaticPosting
          open={autoPostingModal}
          onClose={() => setAutoPostingModal(false)}
          onConfirm={() => handleConfirmConsent(isPaused)}
          deletingPost={confirmingConsent}
          isPaused={isPaused}
        />
      )}

      {instagramConnectModal && (
        <ConnectInstagram
          open={instagramConnectModal}
          onClose={handleInstagramModalClose}
          handleRefresh={handleInstagramConnectRefresh}
          data={user?.userInfo || {}}
          handleConsent={() => handleConfirmConsent(true)}
        />
      )}
    </div>
  );
}
