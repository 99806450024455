import { Button } from "../../../components/Button";
import { ReactComponent as GetStartedIcon } from "../../../assets/get-started-icon.svg";

export function GetStarted({
  confirmingConsent,
  onInstagramConnect,
}: {
  confirmingConsent?: boolean;
  onInstagramConnect: () => void;
}) {
  return (
    <div>
      <div className="bg-white rounded-lg border border-[#E4C270] py-6 mb-6 px-5 flex flex-col md:flex-row justify-between gap-12">
        <div className="flex flex-col md:flex-row flex-1 gap-6 items-center md:items-start ">
          <GetStartedIcon className="min-w-[64px] min-h-[64px] w-16 h-16" />
          <div className="flex flex-col gap-3">
            <span className="text-dark1 text-base font-medium">
              Unlock your Guidely subscription's full potential!
            </span>
            <p className="text-dark2 text-sm">
              With a one-time approval, allow Guidely to auto-post your drafted
              content, empowering you to focus on strategic tasks. You maintain
              complete control — edit, delete, or reschedule posts anytime with
              a click on 'Schedule'.{" "}
              <a
                href="https://coaches.guidely.com/knowledge-base/using-an-instagram-business-account"
                className="text-primary font-semibold"
                target="_blank"
                rel="noreferrer"
              >
                Learn More
              </a>
            </p>
          </div>
        </div>
        <div className="flex items-center">
          <Button
            onClick={onInstagramConnect}
            loading={confirmingConsent}
            disabled={confirmingConsent}
            variant="primary"
            className="w-full md:w-auto"
          >
            Approve & Connect
          </Button>
        </div>
      </div>
    </div>
  );
}
