import React, {useState} from 'react'

import {ActionButtons} from './ActionButtons'
import {OnboardingStepsEnum, OnboardingStepsNamesEnum} from '../../../types'
import {clearTokenAndUser} from '../../../services/utils'
import {useNavigate} from 'react-router-dom'
import {MainUserProfile} from '../../../components/MainUserProfile'
import {
  MainUserProfileContext,
  MainUserProfileProvider,
} from '../../../components/MainUserProfile/MainUserProfile.context'
import {Modal} from '../../../components/Modal'

const ReturnedUserModal = ({
                             existingUserModalState,
                             onModalClose,
                           }: { existingUserModalState: boolean, onModalClose: () => void }) => {

  return <Modal onClose={onModalClose} open={existingUserModalState}>
    <div className="px-5 py-4">
      <h1 className="ss:text-lg ss:leading-7 text-dark1 md:text-2xl font-semibold md:leading-loose">Welcome to the New & Improved Guidely!</h1>
      <div className="ss:text-sm md:text-base text-[#484848]">To enhance the quality of the posts we create for you, we’ve streamlined our onboarding process and introduced exciting new features in the product! In this quick onboarding, we’ll re-capture some essential details, and you’ll have the option to upload your brand fonts or choose from a wide selection we offer. Additionally, once you’re in the product, you’ll now be able to add multiple offerings, testimonials, and topics you'd like covered in your posts—helping us make your content even more aligned with your brand.
        For any questions, reach out to us at <a href="mailto:hello@guidely.com" className="font-semibold text-[#0091AE] hover:cursor-pointer">hello@guidely.com</a>.</div>
      </div>
  </Modal>
}

export function WelcomeCouch({
                               onNext,
                               stepCode,
                             }: {
  onBack: () => void;
  onNext: (data: any) => void;
  metaData: any;
  data;
  stepCode: OnboardingStepsEnum
}) {
  const navigate = useNavigate()
  const [modalState, setModalState] = useState(false)

  const onModalClose = () => {
    setModalState(true)
  }

  const handleClose = async () => {
    clearTokenAndUser()
    navigate('/login')
  }

  return <div className="w-full overflow-auto max-h-[calc(100vh-125px)] py-2">
    <MainUserProfileProvider stepCode={OnboardingStepsNamesEnum.WELCOME_COACH}
                             onSaved={onNext}>
      <MainUserProfileContext.Consumer>
        {({handleNext, loading, form, currentStepData}) => {
          const disabled = !(form.displayName && form.instagramHandle &&
              form?.keywords?.length)
          const existingUserModalState = currentStepData?.isOldUser &&
              !modalState
          return <>
            {existingUserModalState ?
                <ReturnedUserModal existingUserModalState={existingUserModalState} onModalClose={onModalClose}/> :
                null}
            <MainUserProfile isExitButtonEnabled={true}
                             handleExitButton={handleClose}/>
            <div className="my-8 border-t border-lightGray"></div>
            <ActionButtons
                onNext={handleNext}
                disableNext={disabled}
                loading={loading}
            />
          </>
        }}
      </MainUserProfileContext.Consumer>
    </MainUserProfileProvider>
  </div>
}
