import React, {useState} from 'react'
import FontPicker from 'react-fontpicker-ts'

import 'react-fontpicker-ts/dist/index.css'
import './fontpicker.css'
import {serifFonts, sansSerifFonts} from './google-fonts'
import {IBrandStyleTypography} from '../../types'
import {BrandStyleTab} from '../BrandStyles'
import {Button} from '../Button'
import {Modal} from '../Modal'
import {Loading} from '../Loading/Loading'

export const BrandFonts = ({
                             fonts,
                             onUpdate,
                             onRefresh,
                           }: {
  fonts?: IBrandStyleTypography;
  onUpdate: (data: any) => any;
  onRefresh?: () => void
}) => {
  const [edit, setEdit] = useState<boolean>(false)
  const [editFonts, setEditFonts] = useState<IBrandStyleTypography | undefined>(
      fonts)
  const [saving, setSaving] = useState<boolean>(false)

  const handleEdit = () => {
    setEdit(true)
    setEditFonts(fonts)
  }

  const handleClose = () => {
    setEdit(false)
  }

  const handleUpdate = async () => {
    setSaving(true)
    const response = await onUpdate(editFonts)
    setSaving(false)
    if (response) {
      setEdit(false)
    }
  }

  return (<div className={'border border-gray-200 rounded-lg p-4 mt-5'}>
    <BrandStyleTab label="Fonts" onEdit={handleEdit}
                   onRefresh={onRefresh} disabled={!(!!fonts)}/>
    {fonts ? (<>
      <FontPicker loaderOnly={true}
          // fontsLoaded={(loaded) => setFontsLoaded(loaded)}
                  fontsLoadedTimeout={10} loadFonts={[
        fonts.h1['font-family'],
        fonts.h2['font-family']]}/>
      <div className={'flex'}>
        <div className="grid w-1/2 p-3 items-end">
          <div
              className="ss:text-xl md:text-4xl"
              style={{fontFamily: fonts.h1['font-family']}}>{fonts.h1['font-family']}</div>
          <div className="ss:text-ss sm:text-base">Primary Font</div>
        </div>
        <div className="grid w-1/2 p-3 items-end">
          <div
              className="ss:text-xl md:text-4xl"
              style={{fontFamily: fonts.h2['font-family']}}>{fonts.h2['font-family']}</div>
          <div className="ss:text-ss sm:text-base">Secondary Font</div>
        </div>
      </div>
      <Modal
          contentClassName="max-w-[80%] !mx-2"
          open={edit}
          onClose={handleClose}
      >
        <div>
          <div className="text-neutral-800 text-2xl font-semibold px-3">
            Edit Fonts
          </div>
          <div className="flex gap-4 flex-wrap mt-4 flex-col md:flex-row">
            <div className="flex w-full sm:flex-row ss:flex-col">
              <div className="flex-1 ss:w-full md:w-1/2 p-3">
                <FontPicker googleFonts={serifFonts}
                            defaultValue={fonts.h1['font-family']}
                            value={(font) => setEditFonts(currentFonts => {
                              if (!currentFonts) return
                              return {
                                ...currentFonts,
                                h1: {
                                  ...currentFonts.h1,
                                  'font-family': font,
                                },
                              }
                            })}
                />
                <div className="text-base">Primary Font</div>
              </div>
              <div className="flex-1 ss:w-full md:w-1/2 p-3">
                <FontPicker googleFonts={sansSerifFonts}
                            defaultValue={fonts.h2['font-family']}
                            value={(font) => setEditFonts(currentFonts => {
                              if (!currentFonts) return
                              return {
                                ...currentFonts,
                                h2: {
                                  ...currentFonts.h2,
                                  'font-family': font,
                                },
                              }
                            })}
                />
                <div className="text-base">Secondary Font</div>
              </div>
            </div>
          </div>
          <div className="mt-8 flex justify-center">
            <Button variant="primary" onClick={handleUpdate} loading={saving}>
              Update
            </Button>
          </div>
        </div>
      </Modal>
    </>) : <Loading/>}

  </div>)

}