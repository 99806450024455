import React, {useRef, useState} from 'react'

import {
  convertImagesToUrls,
  useBusinessInfoContext,
} from './BusinessInfo.context'
import {Button} from '../Button'
import {InputLabel} from '../InputLabel'
import {TextArea} from '../TextArea'
import {TextInput} from '../TextInput'
import {FileInput} from '../FileInput'
import {ReactComponent as CloseIcon} from '../../assets/x.svg'
import {ReactComponent as InfoIcon} from '../../assets/ic_info.svg'
import {Loading} from '../Loading/Loading'
import {JOURNEY_MAX_LENGTH, JOURNEY_MIN_LENGTH} from './BusinessInfo.constants'
import {Modal} from '../Modal'

export type BusinessInfoParams = {
  isExitButtonEnabled?: boolean
  isHeadlineAllowed?: boolean
  handleExitButton?: () => void
}

export const BusinessInfoJourneyDescription = () => {
  return <>
    <p>The more details you give us, the better quality posts we can
      create for you. You can also add more details later.</p>
    <div className="py-5 ss:font-bold xl:font-normal">
      <p>Here are a few questions to help you get started<b className="xl:hidden" style={{color: 'red'}}>*</b>:</p>
      <ol className={'list-decimal list-inside'}>
        <li>What led you to become a coach or wellness practitioner?
        </li>
        <li>What methods or systems do you use to help people?</li>
        <li>Who are your ideal clients and what problems do you help
          solve
          for them?
        </li>
      </ol>
    </div>
  </>
}

export const BusinessInfo = ({
                               isExitButtonEnabled,
                               handleExitButton,
                               isHeadlineAllowed = true,
                             }: BusinessInfoParams) => {
  const {
    form,
    loading,
    currentStepData,
    errors,
    urls,
    images,
    setForm,
    setErrors,
    setUrls,
    setImages,
  } = useBusinessInfoContext()
  const uploadRef = useRef<HTMLInputElement>(null)
  const [isDescriptionOpen, setDescriptionOpened] = useState(false)

  if (!currentStepData) {
    return <Loading/>
  }

  const handleRemove = (index: number) => {
    const currentImages = images.filter((_, i) => i !== index)
    setImages(currentImages)
    setUrls(convertImagesToUrls(currentImages))
  }

  const onCloseError = (fieldName: string) => () => {
    setErrors(prev => {
          const state = {...prev}
          delete state[fieldName]
          return state
        },
    )
  }

  const handleDescriptionToggle = () => {
    setDescriptionOpened((state) => !state)
  }

  return <>
    {isHeadlineAllowed ? <div className={'flex place-content-between'}>
      <div>
        <h1 className="text-dark1 font-semibold ss:pb-2 xs:pb-2 ss:leading-7 ss:text-xl lg:text-2xl md:pb-0 sm:leading-12">
          {currentStepData?.title}
        </h1>
      </div>
      {isExitButtonEnabled ?
          <div className="items-end xs:hidden ss:hidden sm:flex">
            <Button variant={'primary'}
                    className="btn btn-primary relative right-1 top-1 mt-0 mr-0 mb-5 p-3"
                    onClick={handleExitButton}>
              Exit
            </Button>
          </div>
          : null}
    </div> : null}
    <div
        className="flex w-full flex-wrap flex-col md:flex-row ss:flex-col-reverse">
      <div className="flex-1 ss:w-full xl:w-3/5">
        <div
            className="grid grid-flow-row auto-rows-max gap-4">
          <div className="w-full ss:mt-0 xl:mt-5 relative">
            <InputLabel isRequired={true} className="pr-6 ss:hidden xl:block">
              Tell Us About Your Journey, Your Coaching Style, and Who You
              Help
            </InputLabel>
            <div className="xl:hidden">
              <BusinessInfoJourneyDescription />
            </div>
            <TextArea
                name={'journey'}
                onChange={(e) =>
                    setForm((prev) => ({
                      ...prev,
                      journey: e.target.value,
                    }))
                }
                value={form?.journey}
                disabled={loading}
                isWordCounterEnabled={true}
                minLength={JOURNEY_MIN_LENGTH}
                maxLength={JOURNEY_MAX_LENGTH}
                rows={6}
                onClose={onCloseError('journey')}
                hasError={!!errors.journey}
                errorMessage={errors.journey}
            />
          </div>
          <div className="w-full mt-5">
            <InputLabel isRequired={false}>
              Add Your Website URL
            </InputLabel>
            <TextInput placeholder="Website URL"
                       onChange={(e) =>
                           setForm((prev) => ({
                             ...prev,
                             website_url: e.target.value,
                           }))
                       }
                       onClose={onCloseError('website_url')}
                       hasError={!!errors.website_url}
                       errorMessage={errors.website_url}
                       value={form?.website_url || ''}
                       disabled={loading}
                       maxLength={255}
                       size={50}
            />
          </div>
        </div>
      </div>
      <div
          className="flex-1 ss:hidden xl:block xl:w-2/5 pr-6 py-6 pl-12 self-top text-base text-[#484848]">
        <BusinessInfoJourneyDescription/>
      </div>
      <Modal onClose={handleDescriptionToggle} open={isDescriptionOpen}>
        <BusinessInfoJourneyDescription/>
      </Modal>
    </div>
    <div className={'grid w-full mt-5'}>
      <InputLabel isRequired={true}>
        Upload Headshots
      </InputLabel>
      <div className="flex w-full flex-wrap ss:gap-3 xs:gap-4">
        {/** invisible input type file with div to upload file */}
        {images.length < 10 && (
            <div
                className="w-24 h-24 relative bg-neutral-100 rounded-lg flex justify-center items-center">
              <input
                  type="file"
                  className="hidden"
                  ref={uploadRef}
                  multiple
                  accept="image/*"
                  onChange={(e) => {
                    const files = e.target.files
                    if (files?.length) {
                      const inputImages = [
                        ...images,
                        ...Array.from(files)].filter((_, i) => i < 10)
                      setImages(inputImages)
                      setUrls(convertImagesToUrls(inputImages))
                    }
                    e.target.value = ''
                  }}
              />
              <FileInput
                  onClick={() => uploadRef.current?.click()}
                  disabled={loading}
              />
            </div>
        )}
        {urls.map((image, index) => (
            <div
                key={image}
                className="w-24 h-24 relative bg-neutral-100 rounded-lg flex justify-center items-center"
            >
              <button
                  onClick={() => handleRemove(index)}
                  className="transition duration-75 hover:scale-110 absolute bg-primary rounded-full -top-2 -right-2 p-1"
                  disabled={loading}
              >
                <CloseIcon className="w-4 h-4"/>
              </button>
              <img
                  src={image}
                  alt="media"
                  className="w-full h-full object-cover rounded-lg border border-color-gray"
              />
            </div>
        ))}
      </div>
    </div>
  </>

}