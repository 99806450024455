import { Menu, Transition } from "@headlessui/react";
import { ReactComponent as FilterListIcon } from "../../../assets/ic_filter_list.svg";
import { Fragment } from "react";

export function ScheduleFilter({
  handleFilter,
  onTypeChange,
  scheduleTypes,
  selectedFilter,
  postTypeFilter,
}) {
  return (
    <Menu>
      <Menu.Button>
        <div className="flex gap-1 items-center">
          <span className="text-dark3 text-sm">
            {selectedFilter
              ? scheduleTypes.find((i) => i.name === selectedFilter)?.label
              : "Filter"}
          </span>
          <FilterListIcon />
        </div>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute shadow mt-[100px] right-6 bg-white rounded-md z-[9999] py-2 min-w-[150px]">
          <Menu.Item>
            <div className="flex ">
              <button
                className={`text-center flex-1 text-sm text-dark3 font-medium px-6 py-2 w-full text-left hover:bg-black/5 ${
                  postTypeFilter === "all"
                    ? "border-b-[3px] border-b-primary"
                    : ""
                }`}
                onClick={() => onTypeChange("all")}
              >
                All
              </button>
              <button
                onClick={() => onTypeChange("draft")}
                className={`text-center flex-1 text-sm text-dark3 font-medium px-6 py-2 w-full text-left hover:bg-black/5 ${
                  postTypeFilter === "draft"
                    ? "border-b-[3px] border-b-primary"
                    : ""
                }`}
              >
                Draft
              </button>
            </div>
          </Menu.Item>
          <Menu.Item>
            <button
              onClick={() => handleFilter(null)}
              className="flex items-center gap-2 px-6 py-2 w-full text-left hover:bg-black/5"
            >
              <div className="text-sm text-dark3 font-medium">All</div>
            </button>
          </Menu.Item>
          {scheduleTypes.map((item, index) => (
            <Menu.Item key={index}>
              <button
                onClick={() => handleFilter(item.name)}
                className="flex items-center gap-2 px-6 py-2 w-full text-left hover:bg-black/5"
              >
                <div className={`w-4 h-4 rounded ${item.colorClass}`}></div>
                <div className="text-sm text-dark3 font-medium">
                  {item.label}
                </div>
              </button>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
