import Tippy from "@tippyjs/react";
import { ReactComponent as Info } from "../../../assets/ic_info.svg";

export function SocialScore({ score }: { score: number }) {
  return (
    <div className="bg-white shadow w-full md:w-[259px] p-6 rounded-lg">
      <div className="flex gap-2 items-center">
        <h3 className="text-dark1 text-lg font-semibold">Your Social Score</h3>
        <Tippy
          className="my-tooltip"
          arrow={true}
          interactive={true}
          hideOnClick={true}
          content={
            <div className=" p-4 bg-white rounded-[10px] shadow">
              Your social score is calculated based on a combination of the
              change in your following and the change in your engagement over
              time.
            </div>
          }
        >
          <div className="flex items-center gap-1">
            <Info />
          </div>
        </Tippy>
      </div>
      <div className="progress relative m-1 text-center flex flex-col items-center h-24 mb-6 mt-12">
        <div className="barOverflow relative overflow-hidden w-[180px] h-[90px] mb-[-14px]">
          <div
            className="bar absolute top-0 left-0 w-[180px] h-[180px] rounded-[50%] box-border border-[12px] border-[#F4EFE5] border-b-primary border-r-primary"
            style={{ transform: `rotate(${45 + score * 1.8}deg)` }}
          ></div>
        </div>
        <span className="text-dark1 text-[2rem] font-bold absolute bottom-0">
          {score ? Number(score).toFixed(0) : score}
        </span>
      </div>
      <p className="text-dark3 text-sm text-center">
        {score === 0
          ? "Awaiting your score after the first month. Keep up the good work, and let's achieve great things together!"
          : "Good job! Your social score increased from last month"}
      </p>
    </div>
  );
}
