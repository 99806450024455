import { Link } from "react-router-dom";
import { ReactComponent as Check } from "../../../assets/check.svg";

export function ForgotPasswordSuccess() {
  return (
    <div className="w-96 h-64 p-8 bg-white rounded-lg flex-col justify-start items-center gap-5 inline-flex">
      <div className="w-16 h-16 p-6 rounded-full border border-green-600 justify-center items-center gap-2.5 inline-flex">
        <Check className="!min-w-[36px] h-9" />
      </div>
      <div className="self-stretch h-12 flex-col justify-start items-center gap-2 flex">
        <div className="self-stretch text-center text-black text-lg font-normal font-['Inter'] leading-normal">
          We have sent you an email with instructions to reset your password and
          reset password link.
        </div>
      </div>
    </div>
  );
}
