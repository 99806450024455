import { useCallback, useEffect, useRef } from "react";
import TagManager from "react-gtm-module";
import { api } from "../../services/api";
import React from "react";
import { getUser } from "../../services/utils";
import { Button } from "../../components/Button";
import { ShowToast } from "../../services/toast";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as Lock } from "../../assets/lock.svg";
import { ReactComponent as GuidelyIcon } from "../../assets/Logo.svg";
import { ReactComponent as MagicWand } from "../../assets/payment-magic-wand.svg";
import { ReactComponent as Assignment } from "../../assets/ic_assignment_ind.svg";
import { ReactComponent as DollarIcon } from "../../assets/monetization_on_black_24dp.svg";
import { ReactComponent as StarIcon } from "../../assets/star_payment.svg";
import { ReactComponent as NextIcon } from "../../assets/next.svg";

import { pageView } from "../../services/mixpanel";
import { useApp } from "../../services/app-context";
import { GTM_ID } from "../../constant";

const infoData = [
  {
    title: "Save Time",
    description: "Reallocate 60 hours monthly to focus on sharing your gift ",
    Icon: DollarIcon,
  },

  {
    title: "Tailored to You",
    description: "Maximize personalization in your digital presence.",
    Icon: Assignment,
  },

  {
    title: "Succeed Effortlessly",
    description: "Unique content strategy diversifies your feed",
    Icon: StarIcon,
  },

  {
    title: "Content Creation Wizard",
    description: "Seamlessly craft visuals and captions ",
    Icon: MagicWand,
  },
];

function InfoView({ Icon, title, description }) {
  return (
    <div className="max-w-[150px] md:max-w-[200px]">
      <Icon className="w-6 h-6" />
      <div className="text-sm font-semibold mb-1 mt-2">{title}</div>
      <div className="text-sm">{description}</div>
    </div>
  );
}

export function PlanSelection() {
  const navigate = useNavigate();
  const location = useLocation();
  const childWindowRef = useRef<any>(null);
  const popUpIntervalRef = useRef<any>(null);
  const user = getUser();
  const app = useApp() as any;
  const userId = user._id;

  const [loadingURL, setLoadingURL] = React.useState<boolean>(false);
  const [stripeData, setStripeData] = React.useState<any>({});
  const [isVerified, setIsVerified] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<any>({ tier: "ST", planType: "M" });

  const receiveMessage = useCallback(
    (event: MessageEvent) => {
      if (event.origin === window.location.origin) {
        if (event.data.code === "error" && event.data.from === "Stripe") {
          childWindowRef.current && childWindowRef.current.close();
          setLoadingURL(false);
        } else if (event.data.from === "Stripe") {
          if (childWindowRef.current) {
            childWindowRef.current && childWindowRef.current.close();
            childWindowRef.current = null;
            // do something with the code
            // need to check if subscription is done or not
            // if connected, then show the username
            // if not connected, then show the button
            // API call to get the subscription data
            setLoadingURL(true);
            api
              .getSubscription(userId)
              .then(async (res) => {
                if (res.status === 200) {
                  // setInstagramInfo(res.data);
                  // if it has subscription data with paid status then redirect to dashboard
                  // else stay on the same page
                  const subscriptionData = res.data;
                  if (
                    subscriptionData.length > 0 &&
                    subscriptionData[0].status === "Paid"
                  ) {
                    const tagManagerArgs = {
                      gtmId: GTM_ID,
                      dataLayer: {
                        event: "Subscription",
                        planType: subscriptionData[0]?.planType,
                        plan: subscriptionData[0]?.tier,
                        userId: subscriptionData[0]?.userId,
                        email: subscriptionData[0]?.customerEmail,
                        subscriptionId: subscriptionData[0]?.subscriptionId,
                        amount: Number(subscriptionData[0]?.amount || 0) / 100, // stripe sends amount in 100 denometer so just divided by 100.
                        currency: subscriptionData[0]?.currency,
                        checkoutSessionId:
                          subscriptionData[0]?.checkoutSessionId,
                      },
                    };
                    if (TagManager.dataLayer) {
                      TagManager.dataLayer(tagManagerArgs);
                    }

                    app.setPaymentInfo && app.setPaymentInfo(subscriptionData);
                    await app.refetchPaymentInfo();
                    //refresh screen
                    // window.location.reload();
                    if (location?.state?.from) {
                      navigate(location?.state.from);
                    } else {
                      setTimeout(() => {
                        navigate("/dashboard?subscribed=true");
                      }, 5000)
                    }
                  } else {
                    // subscription not paid
                    ShowToast({
                      type: "error",
                      message:
                        "Unable to verify subscription, please try again later or contact support",
                    });
                  }
                }
              })
              .catch((err) => {
                ShowToast({
                  type: "error",
                  message:
                    "Failed to load subscription, please try again later or contact support",
                });
              })
              .finally(() => {
                setLoadingURL(false);
              });
          }
        }
      }
    },
    [userId, app, location?.state?.from, navigate]
  );

  const handleStripeSubscription = async () => {
    if (!form.tier || !form.planType) {
      ShowToast({
        type: "error",
        message: "Please select a plan and subscription type",
      });
      return;
    }
    setLoadingURL(true);
    childWindowRef.current = window.open("about:blank", "stripe", "_blank");
    api
      .connectStripe(userId, form.tier, form.planType)
      .then((res) => {
        if (res.status === 200) {
          setStripeData(res.data);
          // setLoadingURL(false);
          if (res.data.url) {
            // if safari then open in new tab

            // if (/iPad|iPhone|iPod|Safari/.test(navigator.userAgent)) {
            setTimeout(() => {
              childWindowRef.current.location = res.data.url;
            }, 500);
            // } else {
            //   childWindowRef.current = window.open(
            //     res.data.url,
            //     "stripe",
            //     "_blank"
            //   );
            // }
            if (popUpIntervalRef.current) {
              window.clearInterval(popUpIntervalRef.current);
              popUpIntervalRef.current = null;
            }

            popUpIntervalRef.current = window.setInterval(() => {
              try {
                if (childWindowRef.current && childWindowRef.current.closed) {
                  // if (!hasUserClickedDone && onError) {
                  //   onError();
                  // }
                  setLoadingURL(false);
                  if (popUpIntervalRef.current !== null) {
                    window.clearInterval(popUpIntervalRef.current);
                    popUpIntervalRef.current = null;
                  }
                }
              } catch (error) {
                console.error(error);
                if (popUpIntervalRef.current !== null) {
                  window.clearInterval(popUpIntervalRef.current);
                  popUpIntervalRef.current = null;
                }
              }
            }, 1000);
          } else {
            setLoadingURL(false);
            ShowToast({
              type: "error",
              message: "Something went wrong",
            });
          }
        }
      })
      .catch((e) => {
        if (childWindowRef.current) {
          childWindowRef.current.close();
          childWindowRef.current = null;
        }
        ShowToast({
          type: "error",
          message: "Something went wrong while creating subscription",
        });
        setLoadingURL(false);
      })
      .finally(() => {});
  };

  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
    return () => {
      window.removeEventListener("message", receiveMessage, false);

      if (childWindowRef.current) {
        childWindowRef.current.close();
        childWindowRef.current = null;
      }

      // need for checking if window is closed
      if (popUpIntervalRef.current) {
        window.clearInterval(popUpIntervalRef.current);
        popUpIntervalRef.current = null;
      }
    };
  }, [receiveMessage]);

  useEffect(() => {
    if (isVerified) return;
    setIsVerified(false);
    if (!user.isEmailVerified) {
      navigate("/login");
      setIsVerified(false);
    } else {
      api
        .getSubscription(user._id)
        .then((subscriptionResponse) => {
          if (
            subscriptionResponse?.data?.length > 0 &&
            subscriptionResponse.data[0]?.status === "Paid"
          ) {
            if (user?.isOnboardingCompleted) {
              navigate("/dashboard");
            } else {
              navigate("/onboarding");
            }
          } else {
            setIsVerified(true);
          }
        })
        .catch((err) => {
          setIsVerified(true);
        });
    }
  }, [user, isVerified, navigate]);

  useEffect(() => {
    pageView();
  }, []);

  const renderForm = () => {
    return (
      <>
        <div className="relative rounded-lg p-4 md:p-6 bg-white flex flex-col items-center">
          <div className="mb-4 text-dark1 text-left w-full text-xl md:text-2xl font-semibold">
            Select your plan
          </div>
          <div className="text-sm text-[#84818A] text-left w-full text-xl">
            Pick your preferred plan below
          </div>

          <div className="w-full flex flex-col gap-2 mt-12">
            <div className="flex gap-2 flex-col items-center">
              <div className="flex flex-col mb-1 gap-2 w-full">
                <div
                  role="button"
                  onClick={() =>
                    setForm((prev) => ({ ...prev, planType: "M" }))
                  }
                  className={`flex p-4 border ${
                    form.planType === "M"
                      ? "border-primary"
                      : "border-[#E8E8E8]"
                  } flex-1 rounded-md`}
                >
                  <div className="flex flex-1 flex-col group cursor-pointer">
                    <div className="text-[#202020] text-base mb-4">
                      Monthly Subscription{" "}
                    </div>
                    <div className="text-primary text-sm font-medium mb-1">
                      $99/monthly
                    </div>
                    <div className="text-[10px] text-[#84818A]">
                      Satisfaction guaranteed— full refund within 30 days
                    </div>
                  </div>
                  <div className="flex items-center">
                    {form.planType === "M" && (
                      <NextIcon
                        className="[&_path]:stroke-primary"
                        width={20}
                        height={20}
                      />
                    )}
                  </div>
                </div>
                <div
                  role="button"
                  onClick={() =>
                    setForm((prev) => ({ ...prev, planType: "Y" }))
                  }
                  className={`flex p-4 border ${
                    form.planType === "Y"
                      ? "border-primary"
                      : "border-[#E8E8E8]"
                  } flex-1 rounded-md`}
                >
                  <div className="flex flex-1 flex-col group cursor-pointer">
                    <div className="text-[#202020] text-base mb-4">
                      Yearly Subscription{" "}
                      <span className="text-primary">(SAVE 30%) </span>
                    </div>
                    <div className="text-primary text-sm font-medium mb-1">
                      $80/monthly
                    </div>
                    <div className="text-[10px] text-[#84818A]">
                      Satisfaction guaranteed— full refund within 90 days.
                      Prorated within 365 days
                    </div>
                  </div>
                  <div className="flex items-center">
                    {form.planType === "Y" && (
                      <NextIcon
                        className="[&_path]:stroke-primary"
                        width={20}
                        height={20}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center flex-1 mt-8">
              <Button
                variant="primary"
                onClick={handleStripeSubscription}
                loading={loadingURL}
                className="w-full"
                disabled={loadingURL || !form.tier || !form.planType}
              >
                Continue
              </Button>
            </div>
          </div>
          <div className="mt-8 flex gap-2 flex-col items-center">
            <Lock className="w-4 h-4" />
            <div className="text-dark2 text-sm font-normal leading-normal ">
              Payment secured by Stripe
            </div>
          </div>
        </div>
      </>
    );
  };

  if (!isVerified) return null;

  return (
    <div className="fixed top-0 bottom-0 right-0 left-0 h-[100vh] flex flex-col bg-black bg-opacity-40">
      {/* <button className="absolute right-10 top-5" onClick={handleClose}>
        <CloseIcon className="w-6 h-6" />
      </button> */}
      <div className="flex-1 bg-ultraLightGray flex items-center">
        <div className="flex-1 max-h-[calc(100vh_-_40px)] overflow-auto lg:justify-center items-center max-w-[950px] w-full bg-ultraLightGray mx-auto px-4 md:px-0 py-4 md:pb-5 md:pt-7 flex flex-col lg:flex-row">
          <div className="flex-1 w-full md:w-[500px] lg:w-auto">
            <div className="md:mx-3 lg:mx-0 mb-5 lg:mb-0">
              <GuidelyIcon className="w-[170px] h-[47px]" />
              <div className="mt-8 mb-5 text-2xl leading-8 md:text-2xl lg:text-[36px] lg:leading-10 font-semibold text-[#58595B]">
                Start Your FREE <br /> 30-Day Trial Now!
              </div>
              <p className="text-left text-dark2 text-sm font-normal leading-normal ">
                Effortlessly create and schedule a month's worth of posts—{" "}
                <br /> all tailored to your unique voice and brand. 
              </p>
              <div className="mt-4 text-left text-neutral-800 text-sm font-medium">
                Cancel anytime, no commitment necessary.
              </div>
              <div className="flex lg:hidden my-5 flex-1 flex-col w-full md:w-[520px]">
                {renderForm()}
              </div>
              <div className="flex flex-row flex-wrap gap-8 mt-8">
                {infoData.map((info, index) => (
                  <InfoView key={index} {...info} />
                ))}
              </div>
            </div>
          </div>
          <div className="hidden lg:flex flex-1 flex-col w-full md:w-[520px]">
            {renderForm()}
          </div>
        </div>
      </div>
    </div>
  );
}
