export const timezones = [
  {
    value: "GMT-12:00",
    label: "(GMT-12:00) BIT",
  },
  {
    value: "GMT-11:00",
    label: "(GMT-11:00) SST",
  },
  {
    value: "GMT-10:00",
    label: "(GMT-10:00) HST",
  },
  {
    value: "GMT-9:00",
    label: "(GMT-9:00) AKST",
  },
  {
    value: "GMT-8:00",
    label: "(GMT-8:00) PST",
  },
  {
    value: "GMT-7:00",
    label: "(GMT-7:00) MST",
  },
  {
    value: "GMT-6:00",
    label: "(GMT-6:00) CST",
  },
  {
    value: "GMT-5:00",
    label: "(GMT-5:00) EST",
  },
  {
    value: "GMT-4:00",
    label: "(GMT-4:00) EDT",
  },
  {
    value: "GMT-3:30",
    label: "(GMT-3:30) NDT",
  },
  {
    value: "GMT-3:00",
    label: "(GMT-3:00) ADT",
  },
  {
    value: "GMT-2:00",
    label: "(GMT-2:00) GST",
  },
  {
    value: "GMT-1:00",
    label: "(GMT-1:00) EGT",
  },
  {
    value: "GMT+00:00",
    label: "(GMT+00:00) UTC",
  },
  {
    value: "GMT+1:00",
    label: "(GMT+1:00) CET",
  },
  {
    value: "GMT+2:00",
    label: "(GMT+2:00) EET",
  },
  {
    value: "GMT+3:00",
    label: "(GMT+3:00) AST",
  },
  {
    value: "GMT+3:30",
    label: "(GMT+3:30) IRST",
  },
  {
    value: "GMT+4:00",
    label: "(GMT+4:00) GST",
  },
  {
    value: "GMT+4:30",
    label: "(GMT+4:30) AFT",
  },
  {
    value: "GMT+5:00",
    label: "(GMT+5:00) PKT",
  },
  {
    value: "GMT+5:30",
    label: "(GMT+5:30) IST",
  },
  {
    value: "GMT+5:45",
    label: "(GMT+5:45) NPT",
  },
  {
    value: "GMT+6:00",
    label: "(GMT+6:00) BIOT",
  },
  {
    value: "GMT+6:30",
    label: "(GMT+6:30) CCT",
  },
  {
    value: "GMT+7:00",
    label: "(GMT+7:00) CCT",
  },
  {
    value: "GMT+8:00",
    label: "(GMT+8:00) AWST",
  },
  {
    value: "GMT+8:45",
    label: "(GMT+8:45) Eucla Time",
  },
  {
    value: "GMT+9:00",
    label: "(GMT+9:00) JST",
  },
  {
    value: "GMT+9:30",
    label: "(GMT+9:30) ACST",
  },
  {
    value: "GMT+10:00",
    label: "(GMT+10:00) AET",
  },
  {
    value: "GMT+10:30",
    label: "(GMT+10:30) LHST",
  },
  {
    value: "GMT+11:00",
    label: "(GMT+11:00) SBT",
  },
  {
    value: "GMT+11:30",
    label: "(GMT+11:30) NFT",
  },
  {
    value: "GMT+12:00",
    label: "(GMT+12:00) NZST",
  },
  {
    value: "GMT+12:45",
    label: "(GMT+12:45) CHAST",
  },
  {
    value: "GMT+13:00",
    label: "(GMT+13:00) Tonga Time",
  },
  {
    value: "GMT+14:00",
    label: "(GMT+14:00) LINT",
  },
];
