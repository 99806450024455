import { Layout } from "./components/Layout";
import { Button } from "../../components/Button";
import { TextInput } from "../../components/TextInput";
import { Link, useSearchParams } from "react-router-dom";
import React from "react";
import { api } from "../../services/api";
import { Password } from "./components/Password";
import { ResetPasswordSuccess } from "./components/ResetPasswordSuccess";
import { ShowToast } from "../../services/toast";

export function ResetPassword() {
  const [params] = useSearchParams();
  const resetPasswordToken = params.get("rt");
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [isPasswordSame, setIsPasswordSame] = React.useState<string>("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // get the form data
    const formData = new FormData(e.currentTarget);
    // convert the form data to an object
    const data = Object.fromEntries(formData);
    // log the results
    try {
      setLoading(true);
      const response = await api.resetPassword({
        resetPasswordToken,
        password: data.password,
      });
      if ([200, 201].includes(response.status)) {
        setSuccess(true);
      }
      setLoading(false);
    } catch (error) {
      ShowToast({
        type: "error",
        message: "Failed to reset password",
      });
      setLoading(false);
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    if (
      data.password &&
      data.confirmPassword &&
      data.password === data.confirmPassword
    ) {
      setIsPasswordSame("");
      setDisabled(false);
    } else {
      if (
        data.password &&
        data.confirmPassword &&
        data.password !== data.confirmPassword
      ) {
        setIsPasswordSame("Password does not match");
      } else {
        setIsPasswordSame("");
      }
      setDisabled(true);
    }
  };

  if (success) {
    return (
      <Layout>
        <div className="flex flex-col w-full md:w-[438px] max-h-[calc(100vh_-_100px)] overflow-auto">
          <div className="rounded-lg p-6 bg-white flex flex-col mx-3">
            <ResetPasswordSuccess />
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="flex flex-col w-full md:w-[438px] max-h-[calc(100vh_-_100px)] overflow-auto">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col w-full flex-1"
          onChange={handleChange}
        >
          <div className="rounded-lg p-6 bg-white flex flex-col mx-3">
            <div className="text-dark1 text-2xl font-semibold leading-loose">
              New Password
            </div>
            <span className="text-dark2 text-sm font-normal leading-normal ">
              Please create a new password that you don’t use on any other site.
            </span>
            <div className="my-6 flex flex-col gap-5">
              <div className="flex flex-col gap-1.5">
                <span className="text-black text-xs font-medium">Password</span>
                <Password
                  placeholder="Create password"
                  className="!p-[14px]"
                  name="password"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-black text-xs font-medium">
                  Confirm Password
                </span>
                <TextInput
                  type="password"
                  placeholder="Re-enter password"
                  className="!p-[14px]"
                  name="confirmPassword"
                />
                {isPasswordSame && (
                  <span className="text-red-500 text-xs font-medium">
                    {isPasswordSame}
                  </span>
                )}
              </div>
            </div>
            <Button
              disabled={disabled}
              loading={loading}
              className="w-full"
              variant="primary"
            >
              Change password
            </Button>
          </div>
        </form>
        <div className="flex text-sm text-dark2 gap-1.5 mt-4 justify-center">
          Just remember?
          <Link
            to="/login"
            className="text-black border-b pb-1 border-dashed border-primary font-medium"
          >
            Sign in
          </Link>
        </div>
      </div>
    </Layout>
  );
}
