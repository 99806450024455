import { Modal } from "../../../components/Modal";

export function Feedback({ open, onClose, data }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      contentClassName="!max-w-[375px] w-full !p-6"
      closeButtonStyle="right-7 top-[26px] [&>svg_path]:stroke-black"
    >
      <h1
        className={`text-black text-lg font-semibold ${
          data.message ? "mb-6" : ""
        } leading-normal max-w-[80%]`}
      >
        {data.title}
      </h1>
      {data.message && <p className="text-sm text-dark3">{data.message}</p>}
    </Modal>
  );
}
