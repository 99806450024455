import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";
import { ReactComponent as Notepad } from "../../../assets/notepad.svg";

export function ConsentConfirmationModal({
  open,
  onClose,
  onConfirm,
  confirmingConsent,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmingConsent: boolean;
}) {
  return (
    <div className="bg-white rounded-lg border border-[#E4C270] py-6 mb-6 px-5 flex flex-col md:flex-row justify-between gap-12">
      <div className="flex flex-col md:flex-row flex-1 gap-6 items-center md:items-start ">
        <div className="min-w-[64px] min-h-[64px] w-16 h-16 bg-ultraLightGray rounded-full flex justify-center items-center">
          <Notepad />
        </div>
        <div className="flex flex-col gap-3">
          <span className="text-dark1 text-base font-medium">
            Unlock your Guidely subscription's full potential!
          </span>
          <p className="text-dark2 text-sm">
            With a one-time approval, allow Guidely to auto-post your drafted
            content, empowering you to focus on strategic tasks. You maintain
            complete control — edit, delete, or reschedule posts anytime with a
            click on ‘Schedule’.
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center">
        <Button
          loading={confirmingConsent}
          onClick={onConfirm}
          variant="primary"
        >
          Approve now
        </Button>
      </div>
    </div>
  );
}
