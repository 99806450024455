import React from 'react'

export type InputLabelParams = {
  isRequired: boolean
  labelText?: string
  children?: React.ReactNode;
}

export function InputLabel({
                             ...props
                           }: React.LabelHTMLAttributes<HTMLLabelElement> & InputLabelParams) {
  const {isRequired, labelText, children, className, ...rest} = props
  return <div className={`flex items-end text-dark2 text-sm leading-normal mb-5 ${className || ''}`}>
    <label {...rest}>{labelText || children}{isRequired ?
        <b style={{color: 'red'}}> *</b> :
        ''}</label>
  </div>

}