import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";
import { ReactComponent as InfoCircle } from "../../../assets/info-circle.svg";

export function AutomaticPosting({
  open,
  onClose,
  onConfirm,
  deletingPost,
  isPaused,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  deletingPost: boolean;
  isPaused: boolean;
}) {
  return (
    <Modal
      contentClassName="max-w-[400px]"
      open={open}
      onClose={onClose}
      showCloseButton={true}
      modalClassName="!overflow-y-hidden !md:overflow-y-auto"
      closeButtonStyle="!right-4 !top-4 [&_path]:stroke-black md:[&_path]:stroke-white md:!-right-8 md:!top-0 "
    >
      <div className="text-center flex flex-col items-center">
        <InfoCircle />
        <div className="text-center text-[#000] text-xl md:text-2xl font-semibold leading-[34px]">
          {isPaused ? "Resume" : "Pause"} Automatic Posting
        </div>
        <div className="mt-3.5 max-w-[450px] text-center text-black text-sm font-normal">
          {isPaused
            ? `All posts scheduled prior to pausing automatic posting remain in 'draft' status. You can locate these by filtering in the schedule section and reschedule at your convenience.`
            : `Pausing automatic posting will change all scheduled posts to 'draft'
          status. These posts will remain as drafts even after you resume
          automatic posting. You can reschedule them at your convenience.`}
        </div>
        <div className="flex gap-2.5 justify-center mt-6">
          <Button loading={deletingPost} onClick={onConfirm} variant="primary">
            I Understand
          </Button>
          <Button disabled={deletingPost} variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}
