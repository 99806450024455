import { useEffect, useState } from "react";
import { ReactComponent as LocationIcon } from "../../../assets/ic_place.svg";
import { useDebounce } from "../../../services/useDebounce";
import instagramAPI from "../../../services/instagram";
import axios from "axios";
import { FACEBOOK_APP_ID, FACEBOOK_APP_SECRET } from "../../../constant";

export function LocationInput({ value, onChange, readOnly }) {
  const [search, setSearch] = useState("");
  const searchQuery = useDebounce(search, 2000);

  return (
    <div className="px-4 py-3 flex gap-3 items-center">
      <LocationIcon />
      <div>
        <input
          type="text"
          placeholder="Add Location"
          className="text-sm w-full border-none !outline-none"
          value={value}
          onChange={(e) => setSearch(e.target.value)}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
}
