import { useEffect, useState } from "react";
import { Modal } from "../../../components/Modal";
import { useUser } from "../../profile/context/user-context";
import { Button } from "../../../components/Button";
import { api } from "../../../services/api";
import { getUser } from "../../../services/utils";
import { ShowToast } from "../../../services/toast";
import { SelectInput } from "../../../components/SelectInput";
import { timezones } from "../../../services/timezones";

export function TimezoneModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: (refresh?: boolean) => void;
}) {
  const { _id } = getUser();
  const { userInfo, setUserInfo, fetchUser } = useUser() as any;

  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState({ timezone: "" });

  const handleClose = () => {
    onClose();
    setForm({
      timezone: userInfo?.timezone,
    });
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      await api.saveUserProfile(_id, form);
      await fetchUser(_id);
      setSaving(false);
      onClose(true);
    } catch (error) {
      setSaving(false);
      ShowToast({
        message: "Something went wrong while saving timezone",
        type: "error",
      });
    }
  };

  useEffect(() => {
    setForm({
      timezone: userInfo?.timezone || "GMT-8:00",
    });
  }, [userInfo]);

  return (
    <Modal
      contentClassName="!max-w-[400px]"
      open={open}
      onClose={onClose}
      modalClassName="!overflow-y-hidden !md:overflow-y-auto"
    >
      <div className="">
        <div className=" text-[#000] text-2xl font-semibold leading-[34px] mb-6">
          Change Timezone
        </div>
        <div>
          <SelectInput
            data={timezones}
            value={form?.timezone}
            onChange={(e) => setForm({ ...form, timezone: e.target.value })}
            showBlankOption={false}
          />
        </div>

        <div className="flex gap-2.5 justify-end mt-12">
          <Button disabled={saving} variant="outline" onClick={handleClose}>
            Cancel
          </Button>
          <Button loading={saving} onClick={handleSave} variant="primary">
            Change
          </Button>
        </div>
      </div>
    </Modal>
  );
}
