import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button";
import { ReactComponent as PostIcon } from "../../../assets/post.svg";
import { CreatePostForm } from "../../schedule/components/CreatePostModal";
import { useEffect, useMemo, useRef, useState } from "react";
import { getUser } from "../../../services/utils";
import { api } from "../../../services/api";
import { trackEvent } from "../../../services/mixpanel";
import { ShowToast } from "../../../services/toast";
import React from "react";
import { PaymentPopup } from "./PaymentPopup";

export function PostView({
  posts,
  refresh,
  initialPostsGenerationStatus,
  isSubscriptionActive,
}: {
  posts: any[];
  refresh: (shouldRefreshWholePage?: boolean) => void;
  initialPostsGenerationStatus?: string;
  isSubscriptionActive: boolean;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const intervalRef = useRef<any>();
  const [showMore, setShowMore] = useState(false);
  const [postDialog, setPostDialog] = useState(false);
  const [postForm, setPostForm] = useState<any>({});
  const [unChangedPostForm, setUnChangedPostForm] = useState<any>({});
  const [saving, setSaving] = useState(false);
  const [savingDraft, setSavingDraft] = useState(false);
  const user = getUser();
  const [showPopup, setShowPopup] = useState(false);

  const fetchMedia = async (
    userId: string,
    shouldRefreshWholePage?: boolean
  ) => {
    refresh(shouldRefreshWholePage);
  };

  const handleDraftSave = (data: any) => {
    handleSchedule(data, true);
  };

  const handleSchedule = (data: any, isDraft?: boolean) => {
    // update
    const object = {
      creationMode: data.creationMode,
      userId: user._id,
      type: data.type,
      imageUrl: data.imageUrl,
      tags: data.tags,
      location: data.location,
      caption: data.caption,
      scheduleDateTime: data.scheduleDateTime,
    };
    if (data._id) {
      // update
      isDraft ? setSavingDraft(true) : setSaving(true);
      api
        .updatePost(data._id, { ...data, ...object })
        .then(async (res) => {
          await fetchMedia(user._id);
          isDraft ? setSavingDraft(false) : setSaving(false);
          if (res.status === 200) {
            ShowToast({
              type: "success",
              message: "Post updated successfully",
            });
            trackEvent("Edit Post", {
              id: data._id,
              type: data.type,
              creationMode: data.creationMode,
              userId: user._id,
            });
          }
        })
        .catch((err) => {
          isDraft ? setSavingDraft(false) : setSaving(false);
          ShowToast({
            type: "error",
            message: "Failed to update post",
          });
        });
    } else {
      isDraft ? setSavingDraft(true) : setSaving(true);
      api
        .createPost(object)
        .then(async (res) => {
          await fetchMedia(user._id);

          trackEvent("Create Post Manually", {
            type: object.type,
            creationMode: object.creationMode,
            userId: object.userId,
          });
          ShowToast({
            type: "success",
            message: "Post created successfully",
          });
          isDraft ? setSavingDraft(false) : setSaving(false);
        })
        .catch((err) => {
          isDraft ? setSavingDraft(false) : setSaving(false);
          ShowToast({
            type: "error",
            message: "Failed to create post",
          });
        });
    }
  };

  const handleViewPost = (post: any) => {
    setPostDialog(true);
    setPostForm({ ...post });
    setUnChangedPostForm(post);
  };

  const fetchDashboardData = async () => {
    intervalRef.current = setInterval(() => {
      refresh(false);
    }, 1000 * 15);
  };

  const handleShowMore = () => {
    if (!isSubscriptionActive) {
      setShowPopup(true);
      // navigate("/subscription", {
      //   state: { from: `${location.pathname}${location.search}` },
      // });
    } else {
      setShowMore(true);
    }
  };

  const handleShowLess = () => {
    setShowMore(false);
  };

  useEffect(() => {
    if (
      initialPostsGenerationStatus &&
      ["I", "IR"].includes(initialPostsGenerationStatus)
    ) {
      fetchDashboardData();
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [initialPostsGenerationStatus]);

  const postGroup = useMemo(() => {
    let newPosts = [...posts];
    let postCount = posts.length;
    // if (initialPostsGenerationStatus === "I") {
    //   if (newPosts.length < 9) {
    //     // return 9 empty posts
    //     const emptyPosts = Array.from({ length: 9 }, (_, i) => ({
    //       _id: `empty-${i}`,
    //       imageUrl: "",
    //     }));
    //     return emptyPosts.reduce((acc, item, index) => {
    //       if (index % 3 === 0) {
    //         acc.push([item]);
    //       } else {
    //         acc[acc.length - 1].push(item);
    //       }
    //       return acc;
    //     }, [] as any[][]);
    //   }
    // }
    if (initialPostsGenerationStatus === "IR") {
      // check posts whose updatedAt is less than 8 minutes from now
      newPosts = newPosts.filter((post) => {
        const updatedAt = new Date(post.updatedAt).getTime();
        const currentTime = new Date().getTime();
        return currentTime - updatedAt < 1000 * 60 * 8;
      });
      postCount = newPosts.length;
    }
    // add empty post to make it divisible by 3 total will be 9 post
    for (let i = 0; i < 9 - postCount; i++) {
      newPosts.push({
        _id: `empty-${i}`,
        imageUrl: "",
      });
    }
    const postToShow = showMore ? newPosts : newPosts.slice(0, 9);
    return postToShow.reduce((acc, item, index) => {
      if (index % 3 === 0) {
        acc.push([item]);
      } else {
        acc[acc.length - 1].push(item);
      }
      return acc;
    }, [] as any[][]);
  }, [posts, showMore]);

  const timestamp = new Date().getTime();
  return (
    <div className="mx-1.5">
      <div className="flex flex-col items-center gap-4 border-t border-[#DBDBDB] mt-11">
        <div className="gap-12 flex">
          <div className="relative border-t border-t-black border-t-[2px] pt-4 flex items-center gap-2">
            <PostIcon />
            <div className="text-black text-xs font-normal tracking-wide">
              POSTS
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 flex flex-wrap md:flex-nowrap md:flex-col gap-4 lg:gap-6 justify-center mb-6">
        {/* {
            posts.map((item) => (
                <div key={item._id} className="w-[250px] h-[250px] bg-black/10 relative">
                  <img src={item?.imageUrl} alt="post" className="w-full h-full object-cover z-[9999] absolute" style={{backdropFilter: 'blur(82px)'}} />
                </div>
            ))
        } */}
        {initialPostsGenerationStatus === "I" && (
          <div className="flex flex-col justify-center items-center my-5">
            <span className="text-xs text-black">Generating your posts</span>
            <div className="flex gap-[2px] mt-5">
              <div className="progress h-2.5 w-2.5 border-2 border-black/60 rounded-full animate-progress"></div>
              <div className="progress h-2.5 w-2.5 border-2 border-black/60 rounded-full animate-progress"></div>
              <div className="progress h-2.5 w-2.5 border-2 border-black/60 rounded-full animate-progress"></div>
            </div>
          </div>
        )}
        {initialPostsGenerationStatus === "IR" && (
          <div className="flex flex-col justify-center items-center my-5">
            <span className="text-xs text-black">Refreshing your visuals</span>
            <div className="flex gap-[2px] mt-5">
              <div className="progress h-2.5 w-2.5 border-2 border-black/60 rounded-full animate-progress"></div>
              <div className="progress h-2.5 w-2.5 border-2 border-black/60 rounded-full animate-progress"></div>
              <div className="progress h-2.5 w-2.5 border-2 border-black/60 rounded-full animate-progress"></div>
            </div>
          </div>
        )}

        {postGroup.map((group, index) => (
          <div key={index} className="flex gap-4 lg:gap-6 items-center w-full">
            {group.map((item) => (
              <React.Fragment key={item._id}>
                {item._id.includes("empty") ? (
                  <>
                    {initialPostsGenerationStatus &&
                    ["I", "IR"].includes(initialPostsGenerationStatus) ? (
                      <div
                        role="button"
                        key={item._id}
                        className="flex-1 h-auto max-h-[335px] animate-pulse bg-[#F4EFE5] relative aspect-square max-w-[335px] cursor-pointer"
                      ></div>
                    ) : (
                      <div className="flex-1" key={item._id} />
                    )}
                  </>
                ) : (
                  <div
                    role="button"
                    onClick={() => handleViewPost(item)}
                    key={item._id}
                    className="flex-1 h-auto max-h-[335px] bg-black/10 relative aspect-square max-w-[335px] cursor-pointer"
                  >
                    <img
                      src={item?.imageUrl + "?t=" + timestamp}
                      alt="post"
                      className="w-full h-full object-cover"
                      style={{ backdropFilter: "blur(82px)" }}
                      loading="lazy"
                    />
                    {/* <div style={{backgroundImage: `url(${item?.imageUrl})`}} className="absolute top-0 bottom-0 left-0 right-0 opacity-80 bg-cover" /> */}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
      <div className="flex justify-center">
        {showMore ? (
          <Button
            onClick={handleShowLess}
            variant="outline"
            className="!py-2 w-full md:w-auto"
          >
            Show Less
          </Button>
        ) : (
          <Button
            onClick={handleShowMore}
            variant="outline"
            className="!py-2 w-full md:w-auto"
          >
            Show More
          </Button>
        )}

        {/* <Link to="/schedule" className="w-full md:w-auto">
          <Button variant="outline" className="!py-2 w-full md:w-auto">
            Find your Schedule
          </Button>
        </Link> */}
      </div>

      <CreatePostForm
        open={postDialog}
        onClose={() => {
          setPostForm({});
          setUnChangedPostForm({});
          setPostDialog(false);
          setSaving(false);
        }}
        onDelete={() => fetchMedia(user._id, false)}
        postForm={postForm}
        setPostForm={setPostForm}
        onSchedule={handleSchedule}
        currentScheduleDateTime={unChangedPostForm.scheduleDateTime}
        onRefresh={(post) => {
          setPostForm({ ...post });
          // setPostDialog(false);
          fetchMedia(user._id, false);
        }}
        saving={saving}
        isSubscriptionActive={isSubscriptionActive}
        onSaveDraft={handleDraftSave}
        savingDraft={savingDraft}
      />
      {showPopup && (
        <PaymentPopup
          isSubscriptionActive={false}
          open={showPopup}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
}
