import toast from "react-hot-toast";
import { ReactComponent as Check } from "../assets/check.svg";
import { ReactComponent as Cross } from "../assets/x.svg";


export function ShowToast({ message, type }: { message: string; type: "success" | "error"}) {
  toast.custom((t) => (
    <div className={`md:max-w-[50vw] px-4 py-2.5 ${type === "success" ? "bg-green-50" : "bg-red-50"} rounded border ${type === "success" ? "border-green-600" : "border-red-600"} border-opacity-20 justify-start items-center gap-1.5 inline-flex`}>
      {type === 'success' ? <Check className="w-5 h-5 relative" /> : <Cross className="w-5 h-5 relative [&>path]:stroke-red-600" /> }
      <div className="text-zinc-900 text-sm font-medium leading-normal">
        {message}
      </div>
    </div>
  ));
}
