import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { Fragment, useEffect, useState } from "react";
import { Button } from "../../components/Button";
import { CreatePostForm } from "./components/CreatePostModal";
import { ReactComponent as RightIcon } from "../../assets/right-chevron.svg";
import { ReactComponent as FilterListIcon } from "../../assets/ic_filter_list.svg";
import { getUser } from "../../services/utils";
import { api } from "../../services/api";
import moment from "moment";
import { AiPostGenerator } from "./AiPostGenerator";
import { pageView, trackEvent } from "../../services/mixpanel";
import { ShowToast } from "../../services/toast";
import { Menu, Transition } from "@headlessui/react";
import { Loading } from "../../components/Loading/Loading";
import { ConsentConfirmationModal } from "../dashboard/components/ConsentConfirmationModal";
import { useUser } from "../profile/context/user-context";
import { TimezoneModal } from "./components/TimezoneModal";
import { timezones } from "../../services/timezones";
import { GetStarted } from "./components/GetStarted";
import { ConnectInstagram } from "../onboarding/components/ConnectInstagram";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useApp } from "../../services/app-context";
import { PaymentBox } from "../dashboard/components/PaymentBox";
import { ScheduleFilter } from "./components/Filter";

const locales = {
  "en-US": enUS,
};

const scheduleTypes = [
  {
    name: "INFORMATIONAL",
    label: "Informational",
    colorClass: "bg-[#688692]",
  },
  {
    name: "CALL_TO_ACTION",
    label: "Call-to-Action (CTA)",
    colorClass: "bg-[#B7D7E4]",
  },
  {
    name: "PERSONAL_POST",
    label: "Personal Post",
    colorClass: "bg-[#C1AD3F]",
  },
  {
    name: "SHARE_YOUR_STORY",
    label: "Share your Story",
    colorClass: "bg-[#E4C270]",
  },
  {
    name: "TESTIMONIALS",
    label: "Testimonials",
    colorClass: "bg-[#EE826B]",
  },
];

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const CustomToolbar =
  ({ onCreate, onAutoGenerate, generatingPost }: any) =>
  (toolbar) => {
    const goToBack = () => {
      // convert by view [week, month]
      if (toolbar.view === "week") {
        toolbar.date.setDate(toolbar.date.getDate() - 7);
      } else {
        toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      }
      toolbar.onNavigate("prev");
    };

    const goToNext = () => {
      if (toolbar.view === "week") {
        toolbar.date.setDate(toolbar.date.getDate() + 7);
      } else {
        toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      }
      toolbar.onNavigate("next");
    };

    const goToCurrent = () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.onNavigate("current");
    };

    // const label = () => {
    //   const date = moment(toolbar.date);
    //   return (
    //     <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
    //   );
    // };

    return (
      <div className="flex justify-between items-center mb-6 flex-wrap gap-4">
        <div className="flex justify-between items-center gap-6">
          <div className="flex flex-col">
            <div className="text-neutral-800 text-lg font-semibold font-['Inter'] leading-normal">
              {toolbar.localizer.format(
                toolbar.date,
                toolbar.localizer.formats.monthHeaderFormat
              )}
            </div>
            <div className="text-zinc-500 text-xs font-normal font-['Inter']">
              Today
            </div>
          </div>
          <div className="flex items-center gap-3">
            <button
              className="border rounded border-solid border-lightGray h-10 w-10 flex justify-center items-center"
              onClick={goToBack}
            >
              <RightIcon className="rotate-180" />
            </button>
            <button
              className="border rounded border-solid border-lightGray h-10 w-10 flex justify-center items-center"
              onClick={goToNext}
            >
              <RightIcon />
            </button>
          </div>
        </div>
        <div className="flex justify-between items-center gap-8 flex-wrap">
          <Button
            variant="primary"
            className="!text-sm !font-normal !py-2 flex items-center"
            onClick={onCreate}
          >
            <div className="!text-xl mb-[2px]">+</div> Create
          </Button>
          <div className="border-lightGray border rounded flex w-fit">
            {toolbar.views.map((view, index) => (
              <button
                key={view}
                type="button"
                onClick={() => toolbar.onView(view)}
                className={`py-3 px-4 text-sm font-normal capitalize ${
                  index < toolbar.view.length - 2
                    ? "border-r border-solid border-lightGray"
                    : ""
                } ${toolbar.view === view ? "text-primary" : "text-dark3"}`}
              >
                {view}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  };

const CustomEvent = ({ event }) => {
  const typeClassName =
    scheduleTypes.find((item) => item.name === event.resource.type)
      ?.colorClass || "bg-[#EE826B]";
  return (
    <div
      role="button"
      onClick={event.resource.onClick}
      className="flex flex-col items-center gap-[2px]"
    >
      <div className="flex justify-between items-center w-full px-[2px]">
        <span className="text-xs text-dark3">
          {moment(event.start).format("HH:mm")}
        </span>
        <div className={`h-1 w-1 rounded-[1px] ${typeClassName}`} />
      </div>
      <div className="w-full h-[57px]">
        <img
          src={event.resource.image}
          alt=""
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  );
};

const CustomEventContainer = ({ children, event }) => {
  return (
    <div className="bg-white rounded-lg shadow-[0px_1px_3px_0px_rgba(0,0,0,0.08)] p-4">
      {children}
    </div>
  );
};

const MyCalendar = (props) => {
  return (
    <div>
      <Calendar
        localizer={localizer}
        events={[
          ...props.posts.map((post) => ({
            start: new Date(post.scheduleDateTime),
            end: new Date(post.scheduleDateTime),
            resource: {
              image: post.imageUrl,
              type: post.type,
              onClick: () => props.onViewPost(post),
            },
          })),
        ]}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "60vh" }}
        views={["week", "month"]}
        defaultView="month"
        components={{
          toolbar: CustomToolbar({
            onCreate: props.onCreatePost,
            generatingPost: props.generatingPost,
            onAutoGenerate: props.onAutoGenerate,
          }),
          eventContainer: CustomEventContainer,
        }}
        formats={{
          timeGutterFormat: (date, culture, localizer) =>
            localizer.format(date, "HH:mm", culture),
          dayFormat: (date, culture, localizer) =>
            localizer.format(date, "EEE dd", culture),
        }}
        titleAccessor={(event) => <CustomEvent event={event} />}
        step={15}
        className="[&_.rbc-time-slot]:min-h-[20px] [&_.rbc-time-slot]:max-h-[20px] [&_.rbc-event]:!h-auto [&_.rbc-event]:!bg-white [&_.rbc-event]:!border-[#0AA630] [&_.rbc-event]:!p-[2px] [&_.rbc-event-label]:hidden"
      />
    </div>
  );
};

export function Schedule() {
  const { instagramConnected, fetchInstagramConnected } = useOutletContext<{
    instagramConnected: boolean;
    fetchInstagramConnected: (userId: string) => void;
  }>();
  const navigate = useNavigate();
  const [showTimezoneModal, setShowTimezoneModal] = useState(false);
  const [showConsentModal, setShowConsentModal] = useState(false);
  const [confirmingConsent, setConfirmingConsent] = useState(false);
  const [showAiGenerator, setShowAiGenerator] = useState(false);
  const [generatingPost, setGeneratingPost] = useState(false);
  const [postDialog, setPostDialog] = useState(false);
  const [postForm, setPostForm] = useState<any>({});
  const [unChangedPostForm, setUnChangedPostForm] = useState<any>({});
  const [posts, setPosts] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  const [postTypeFilter, setPostTypeFilter] = useState<string>("all");
  const [saving, setSaving] = useState(false);
  const [savingDraft, setSavingDraft] = useState(false);
  const user = getUser();
  const app = useApp() as any;
  const { userInfo, fetchUser, loading: userInfoLoading } = useUser() as any;
  const [instagramConnectModal, setInstagramConnectModal] = useState(false);
  const isPaused = !userInfo?.canAutoSchedulePost;

  const handleCreatePost = () => {
    setPostDialog(true);
    setPostForm({
      type: "CALL_TO_ACTION",
      scheduleDateTime: new Date(),
    });
  };

  const fetchPosts = async (userId) => {
    try {
      setLoading(true);
      const res = await api.getPosts(userId);
      setLoading(false);
      setPosts([...res.filter((e) => !e.isDeleted)]);
    } catch (error) {
      setLoading(false);
      ShowToast({
        type: "error",
        message: "Error fetching posts",
      });
    }
  };

  const handleAutoPostGenerate = async () => {
    setShowAiGenerator(true);
    const trackData: any = {
      userId: user?._id,
    };
    if (postForm?._id) {
      trackData.id = postForm?._id;
    }
    trackEvent("Create with Content Wizard", {
      ...trackData,
    });
    // setGeneratingPost(true);
    // const result = await api.createPost({
    //   userId: user._id,
    //   creationMode: "AUTO",
    //   type: "SHARE_YOUR_STORY",
    // });
    // const res = await api.getPosts(user._id);
    // setPosts([...res]);
    // setGeneratingPost(false);
  };

  const handleCloseAiGenerator = () => {
    setShowAiGenerator(false);
  };

  const handleDraftSave = (data: any) => {
    handleSchedule(data, true);
  };

  const handleSchedule = (data: any, isDraft?: boolean) => {
    if (data._id) {
      // update
      isDraft ? setSavingDraft(true) : setSaving(true);
      api
        .updatePost(data._id, { ...data, tags: data.tags })
        .then(async (res) => {
          if (res.status === 200) {
            ShowToast({
              type: "success",
              message: "Post updated successfully",
            });
            await fetchPosts(user._id);
            // setPostDialog(false);
            setPostForm({ ...data, ...res.data });
            setUnChangedPostForm({ ...data, ...res.data });
            trackEvent("Edit Post", {
              id: data._id,
              type: data.type,
              creationMode: data.creationMode,
              userId: user._id,
            });
          }
          isDraft ? setSavingDraft(false) : setSaving(false);
        })
        .catch((err) => {
          isDraft ? setSavingDraft(false) : setSaving(false);
          ShowToast({
            type: "error",
            message: "Failed to update post",
          });
        });
    } else {
      // create
      const obj: any = {
        creationMode: "MANUAL",
        userId: user._id,
        type: data.type,
        imageUrl: data.imageUrl,
        tags: data.tags,
        location: data.location,
        caption: data.caption,
      };
      if (!isDraft) {
        obj.scheduleDateTime = data.scheduleDateTime;
      }

      isDraft ? setSavingDraft(true) : setSaving(true);
      api
        .createPost(obj)
        .then(async (res) => {
          trackEvent("Create Post Manually", {
            type: obj.type,
            creationMode: obj.creationMode,
            userId: obj.userId,
          });
          await fetchPosts(user._id);
          // setPostDialog(false);
          if (res?.data?._id) {
            ShowToast({
              type: "success",
              message: "Post created successfully",
            });
            setPostForm({ ...obj, ...res.data });
            setUnChangedPostForm({ ...obj, ...res.data });
          }
          isDraft ? setSavingDraft(false) : setSaving(false);
        })
        .catch((err) => {
          isDraft ? setSavingDraft(false) : setSaving(false);
          ShowToast({
            type: "error",
            message: "Failed to create post",
          });
        });
    }
  };

  const handleViewPost = (post: any) => {
    setPostDialog(true);
    setPostForm(post);
    setUnChangedPostForm(post);
  };

  const handleFilter = (filterValue: string | null) => {
    setSelectedFilter(filterValue);
  };

  const handlePostTypeFilter = (filter: string) => {
    setPostTypeFilter(filter);
    setSelectedFilter(null);
  };

  const discardOldPost = (id: string) => {
    setPosts((prev) => prev.filter((e) => e._id !== id));
  };

  const filterPostByType = (data, type, postType) => {
    if (!type && postType === "all") {
      return data || [];
    }
    let dataList = [...data];
    if (postType === "draft") {
      dataList = dataList.filter((e) => !e.ayrsharePostId);
    }
    if (!type) {
      return dataList;
    }
    return dataList.filter((item) => item.type === type);
  };
  const closeConsentModal = () => {
    setShowConsentModal(false);
  };

  const handleTimeChangeChangeModal = () => {
    if (app.isSubscriptionActive) {
      setShowTimezoneModal(true);
    } else {
      navigate("/subscription", {
        state: {
          from: "/schedule",
        },
      });
    }
  };

  const closeTimezoneModal = () => {
    setShowTimezoneModal(false);
  };

  const handleInstagramModalClose = () => {
    setInstagramConnectModal(false);
    // handleRefresh();
  };

  const handleInstagramConnectRefresh = () => {
    fetchInstagramConnected(user._id);
    fetchUser(user._id);
  };

  const handleConfirmConsent = async () => {
    setConfirmingConsent(true);
    const response = await api.saveUserProfile(user._id, {
      canAutoSchedulePost: true,
    });
    if ([200, 201].includes(response.status)) {
      await fetchUser(user._id);
      setConfirmingConsent(false);
      setShowConsentModal(false);
    } else {
      ShowToast({
        type: "error",
        message: "Error confirming consent",
      });
    }
  };

  useEffect(() => {
    if (user?._id) {
      fetchPosts(user._id);
    }
  }, [user?._id]);

  useEffect(() => {
    pageView();
  }, []);

  const shouldShowConsent = !userInfo?.canAutoSchedulePost;

  return (
    <div className="relative bg-ultraLightGray p-4 md:p-6">
      <div className="flex flex-wrap justify-between gap-2 mb-8">
        <h1 className="text-neutral-800 text-4xl font-semibold leading-10">
          Schedule
        </h1>
        <div className="flex items-center gap-2 text-sm">
          <span>
            Using{" "}
            {userInfo.timezone
              ? timezones.find((e) => e.value === userInfo.timezone)?.label
              : "(GMT-8:00) PST"}{" "}
            time
          </span>
          <Button
            onClick={handleTimeChangeChangeModal}
            className="!text-primary"
            variant="link"
            disabled={!app.isSubscriptionActive}
          >
            Not your time zone?
          </Button>
        </div>
      </div>
      {!app.isSubscriptionActive ? (
        <PaymentBox
          title="Start Your 30-Day Free Trial"
          description="We create and schedule a month's worth of content for you, every month. Here, you can see all of your scheduled content,
          create and schedule posts from scratch, or co-create posts with our content wizard. "
        />
      ) : !(instagramConnected || instagramConnected === undefined) ? (
        <GetStarted
          onInstagramConnect={() => {
            setInstagramConnectModal(true);
          }}
        />
      ) : null}
      {instagramConnected &&
        shouldShowConsent &&
        !userInfoLoading &&
        Object.keys(userInfo).length > 0 && (
          <ConsentConfirmationModal
            open={showConsentModal}
            onClose={closeConsentModal}
            onConfirm={handleConfirmConsent}
            confirmingConsent={confirmingConsent}
          />
        )}
      <div
        className="relative rounded-lg shadow-[0px_1px_3px_0px_rgba(0,0,0,0.08)] p-6 bg-white"
        // style={{
        //   filter: app?.isSubscriptionActive ? "none" : "blur(5px)",
        //   pointerEvents: app?.isSubscriptionActive ? "auto" : "none",
        // }}
      >
        {!app?.isSubscriptionActive && (
          <div
            className="absolute top-0 bottom-0 left-0 right-0 bg-ultraLightGray z-[10] backdrop-brightness-0"
            style={{
              WebkitMask:
                "-webkit-gradient(linear, left 65%, left 0%, from(rgba(246, 247, 248, 1)), to(rgba(246, 247, 248, 0)))",
            }}
          />
        )}
        <div className="flex flex-col md:flex-row gap-2 md:gap-0">
          <div className="flex-1">
            <MyCalendar
              onCreatePost={handleCreatePost}
              posts={posts.filter((e) => (isPaused ? e.ayrsharePostId : true))} // When paused, show only posts that are already scheduled, if not show all posts
              generatingPost={generatingPost}
              onAutoGenerate={handleAutoPostGenerate}
              onViewPost={handleViewPost}
            />
          </div>
          <div className="w-[300px] pb-6 pl-5 ml-1 pt-3 border border-lightGray">
            <div className="pb-5 pr-6 flex justify-between items-center">
              <h3 className="text-sm text-dark1 whitespace-nowrap">
                Upcoming Posts
              </h3>
              {/* <button className="flex gap-1 items-center">
                <span className="text-dark3 text-sm">Filter</span>
                <FilterListIcon />
              </button> */}
              <ScheduleFilter
                handleFilter={handleFilter}
                onTypeChange={handlePostTypeFilter}
                scheduleTypes={scheduleTypes}
                selectedFilter={selectedFilter}
                postTypeFilter={postTypeFilter}
              />
            </div>
            <div className="flex flex-wrap gap-1.5 max-h-[48vh] overflow-auto">
              {loading && (
                <div className="flex-1 flex justify-center [&>svg]:h-6 [&>svg]:w-6 mt-6">
                  <Loading />
                </div>
              )}
              {filterPostByType(posts, selectedFilter, postTypeFilter)
                ?.length === 0 &&
                !loading && (
                  <div className="flex-1 flex justify-center items-center text-dark3 mt-6">
                    No posts found
                  </div>
                )}
              {filterPostByType(posts, selectedFilter, postTypeFilter)?.map(
                (post, index) => (
                  <button key={index} onClick={() => handleViewPost(post)}>
                    <div className="w-20 h-20" key={index}>
                      <img
                        src={post.imageUrl}
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>
                  </button>
                )
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-center gap-5 mt-6">
          {scheduleTypes.map((item, index) => (
            <div key={index} className="flex items-center gap-2">
              <div className={`w-4 h-4 rounded ${item.colorClass}`}></div>
              <div className="text-sm text-dark3 font-medium">{item.label}</div>
            </div>
          ))}
        </div>
      </div>
      <CreatePostForm
        open={postDialog}
        onClose={() => {
          setSaving(false);
          setPostDialog(false);
        }}
        postForm={postForm}
        setPostForm={setPostForm}
        onSchedule={handleSchedule}
        currentScheduleDateTime={unChangedPostForm.scheduleDateTime}
        onDelete={() => {
          discardOldPost(postForm._id);
          setPostForm({});
          setUnChangedPostForm({});
        }}
        onRefresh={(post) => {
          // setPostDialog(false);
          setPostForm({ ...post });
          setUnChangedPostForm({ ...post });
          fetchPosts(user._id);
        }}
        saving={saving}
        isSubscriptionActive={app.isSubscriptionActive}
        onSaveDraft={handleDraftSave}
        savingDraft={savingDraft}
      />
      {showAiGenerator && (
        <AiPostGenerator
          onClose={handleCloseAiGenerator}
          open={showAiGenerator}
          onRefresh={() => fetchPosts(user._id)}
        />
      )}
      {showTimezoneModal && (
        <TimezoneModal open={showTimezoneModal} onClose={closeTimezoneModal} />
      )}

      {instagramConnectModal && (
        <ConnectInstagram
          open={instagramConnectModal}
          onClose={handleInstagramModalClose}
          handleRefresh={handleInstagramConnectRefresh}
          data={user?.userInfo || {}}
          handleConsent={handleConfirmConsent}
        />
      )}
    </div>
  );
}
