import ReactGA from "react-ga4";
import { MEASUREMENT_ID } from "../constant";

export function initGA() {
  ReactGA.initialize(MEASUREMENT_ID);
}

export function gaPageView() {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}
