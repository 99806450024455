import { Button } from "../../../components/Button";
import { TextArea } from "../../../components/TextArea";
import { scheduleTypes } from "../../../constant";
import { trackEvent } from "../../../services/mixpanel";
import { getUser } from "../../../services/utils";

const labels = {
  TESTIMONIALS:
    "I’m excited to help you share the story of a recent win! Without sharing personal details (like name) tell me about a client you’ve worked with and how they benefited from your support. Even if it was a single session with a client, what’s something they took away?",
  INFORMATIONAL:
    "To help me craft a post for you to share your expertise and add value to your audience, what’s something they can do today to take a step forward? Or what’s something you recommend or frequently tell clients?",
  CALL_TO_ACTION:
    "To help me promote the offering you’re focused on, take a moment to imagine the ideal outcomes for your clients, then share: how would they feel after working with you? What would be their new normal?",
  PERSONAL_POST:
    "Since it’s important your audience feel like they know you, let’s give them a glimpse! What’s a recent accomplishment you’re proud of outside of work? Or what’s a part of your daily routine that you love?",
  SHARE_YOUR_STORY:
    "To help me tell your story, What’s one thing that will never be the same since you’ve been on your transformational journey?",
};

export function PostGeneratorStep2({ onChange, onNext, data, onBack, saving }) {
  const handleNext = () => {
    const user = getUser();
    const trackData: any = {
      type: data?.type,
      outcomeOffering: data?.outcomeOffering,
      userId: user?._id,
    };
    if (data?._id) {
      trackData.id = data?._id;
    }
    //track event
    trackEvent("Content wizard step 2", {
      ...trackData,
    });
    onNext();
  };

  // at least 60 characters
  const disabled = !data.outcomeOffering || data.outcomeOffering?.length < 60;
  return (
    <div className="flex flex-col">
      <div className="text-neutral-800 text-2xl font-semibold mb-3 mt-8">
        {scheduleTypes.find((item) => item.name === data.type)?.label}
      </div>
      <div className="text-zinc-700 text-sm font-normal mb-8">
        {labels[data.type]}
      </div>
      <TextArea
        value={data.outcomeOffering}
        onChange={(e: any) => {
          onChange({ ...data, outcomeOffering: e.target.value });
          // handleRegenerate({...postForm, type: e.target.value})
        }}
        name="outcomeOffering"
        rows={5}
      />
      <div className="flex justify-between mt-9">
        <Button variant="outline" onClick={onBack}>
          Back
        </Button>

        <Button
          variant="primary"
          disabled={disabled}
          onClick={handleNext}
          loading={saving}
        >
          Create my post
        </Button>
      </div>
    </div>
  );
}
