import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getUser } from "../../services/utils";

export function StripeCallback() {
  const [params] = useSearchParams();
  const user = getUser();
  const userId = user._id;

  useEffect(() => {
    window.opener &&
      window.opener.postMessage(
        {
          code: JSON.stringify({ code: `${params.get("code1")}` }),
          from: "Stripe",
        },
        window.location.origin
      );
    window.close();
  }, [params]);

  return (
    <div>
      Redirecting...
      <a
        className="ml-5 text-blue-500"
        href={`${window.location.origin}/subscription`}
      >
        Click here to redirect to your application.
      </a>
    </div>
  );
}
