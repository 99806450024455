import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export function AyrshareCallback() {
  const [params] = useSearchParams();

  useEffect(() => {
    window.opener &&
      window.opener.postMessage(
        {
          code: JSON.stringify({ code: `${params.get("code1")}` }),
          from: "Ayrshare",
        },
        window.location.origin
      );
  }, [params]);

  return (
    <div>
      Redirecting...
      <a
        className="ml-5 text-blue-500"
        href={`${window.location.origin}/onboarding?step=2`}
      >
        Click here to redirect to your application.
      </a>
    </div>
  );
}
