import { Button } from "../../components/Button";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { FACEBOOK_APP_ID } from "../../constant";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { ReactComponent as Facebook } from "../../assets/facebook.svg";
import { ReactComponent as Google } from "../../assets/google.svg";
import { useEffect } from "react";
import React from "react";
import axios from "axios";
import { setToken, setUser as setUserInfo } from "../../services/utils";
import { useNavigate } from "react-router-dom";
import { api } from "../../services/api";
import { ShowToast } from "../../services/toast";

export function SocialLogin({
  responseFacebook,
  onGoogleLogin,
  onSuccess,
}: {
  responseFacebook: (response: any) => void;
  onGoogleLogin?: (response: any) => void;
  onSuccess: (value: boolean) => void;
}) {
  const navigate = useNavigate();
  const [user, setUser] = React.useState<any>();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const handleFacebookLogin = (response: any) => {
    doSocialLogin(response.id, "facebook", response.email);
  };

  const doSocialLogin = async (id: string, type: string, email: string) => {
    try {
      const response = await api.socialLogin({
        socialMediaType: type,
        socialId: id,
        email,
      });
      if (response?.data?._id) {
        const result = response.data;
        if (result.accessToken) {
          setToken(result.accessToken);
          setUserInfo({ ...result });
          if (result.isOnboardingCompleted) {
            navigate("/dashboard");
          } else {
            onSuccess(true);
          }
        }
      }
    } catch (error) {
      ShowToast({
        type: "error",
        message: "Failed to login with social media",
      });
    }
  };

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          doSocialLogin(res.data.id, "google", res.data.email);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  return (
    <div className="flex gap-3">
      <Button
        type="button"
        variant="outline"
        className="flex-1 rounded border !border-gray-200 !gap-2 font-semibold !text-neutral-800"
        onClick={() => login()}
      >
        <Google />
        Google
      </Button>

      <FacebookLogin
        appId={FACEBOOK_APP_ID}
        autoLoad={false}
        fields="name,email"
        callback={handleFacebookLogin}
        version="18.0"
        scope="public_profile,email,instagram_basic"
        render={(renderProps) => (
          <Button
            variant="outline"
            type="button"
            className="flex-1 rounded border !border-gray-200 !gap-2 font-semibold !text-neutral-800"
            onClick={renderProps.onClick}
          >
            <Facebook />
            Facebook
          </Button>
        )}
      />
    </div>
  );
}
