import { ReactComponent as PersonIcon } from "../../../assets/ic_person_add.svg";
import { ChipInput } from "../../../components/ChipInput/ChipInput";

const covertStringToTag = (str = "") => {
  return str
    .trim()
    .split(",")
    .filter((e) => e)
    .map((item) => item.trim());
};

export function TagPeople({ value, onChange, readOnly }) {
  const handleAdd = (newValue) => {
    const list = covertStringToTag(value);
    list.push(newValue);
    onChange(list.join(","));
  };

  const handleRemove = (index) => {
    const list = covertStringToTag(value);
    const newValue = list.slice();
    newValue.splice(index, 1);
    onChange(newValue.join(","));
  };
  return (
    <div className="px-4 py-3 flex gap-3 items-center">
      <PersonIcon />
      <div>
        <ChipInput
          chips={covertStringToTag(value)}
          onSubmit={handleAdd}
          onRemove={handleRemove}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
}
