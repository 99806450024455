import { Layout } from "./components/Layout";
import { Button } from "../../components/Button";
import React from "react";
import { api } from "../../services/api";
import { getUser } from "../../services/utils";
import { Onboarding } from "../onboarding";
import { ReactComponent as Email } from "../../assets/email.svg";
import { ReactComponent as Back } from "../../assets/back.svg";
import OtpInput from "react-otp-input";
import { ReactComponent as AlertIcon } from "../../assets/Alert.svg";

export function ConfirmEmail({
  onSuccess,
  onClose,
}: {
  onSuccess?: () => void;
  onClose?: () => void;
}) {
  const [showOnboarding, setShowOnboarding] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [error, setError] = React.useState("");
  const user = getUser();

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await api.verifyEmail(user._id, {
        otp,
      });
      if ([201, 200].includes(response.status)) {
        onSuccess && onSuccess();
      }
      setLoading(false);
    } catch (e: any) {
      if (e.response.data.message) {
        setError(e.response.data.message);
      } else {
        setError("Something went wrong");
      }
      setLoading(false);
    }
  };

  const sendVerificationCode = async () => {
    if (!user._id) return;
    const response = await api.initEmailVerification(user._id);
  };

  return (
    <Layout>
      <div className="flex flex-col w-full md:w-[438px] max-h-[calc(100vh_-_100px)] overflow-auto">
        <div className="relative rounded-lg p-6 bg-white flex flex-col items-center mx-3">
          <button onClick={onClose} className="absolute top-5 left-5">
            <Back />
          </button>
          <div className="p-6 rounded-full w-fit border border-primary flex">
            <Email className="w-12 h-12" />
          </div>
          <div className="text-dark1 text-2xl font-semibold leading-loose">
            Confirm your email
          </div>
          <p className="text-center text-dark2 text-sm font-normal leading-normal ">
            Please enter the verification code sent to your email{" "}
            <span className="text-black">'{user?.email}'</span> to verify your
            email address.
          </p>
          <div className="my-6 flex flex-col gap-5">
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={() => <div className="w-4 h-px"></div>}
              shouldAutoFocus={true}
              inputType="number"
              renderInput={(props) => (
                <input
                  {...props}
                  className="remove-arrow !w-10 h-12 px-3.5 py-3.5 bg-white rounded border border-gray-200 items-center flex text-sm"
                />
              )}
            />
          </div>
          <Button
            disabled={otp.length < 4}
            loading={loading}
            className="w-full"
            variant="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
          {error && (
            <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
              <AlertIcon /> {error}
            </div>
          )}
          <div className="my-6 h-px border-b w-full border-gray-200"></div>
          <div className="flex text-sm text-dark2 gap-1.5 mt-4 justify-center">
            Didn’t received code?
            <button
              type="button"
              className="text-black border-b pb-1 border-dashed border-primary font-medium"
              onClick={sendVerificationCode}
            >
              Resend
            </button>
          </div>
        </div>
      </div>
      {showOnboarding && (
        <Onboarding onClose={() => setShowOnboarding(false)} />
      )}
    </Layout>
  );
}
