// export const GOOGLE_OAUTH_CLIENT_ID = "732782282804-m0v81v0sni9mhqorp5p7av2sqketkul0.apps.googleusercontent.com";
export const GOOGLE_OAUTH_CLIENT_ID =
  "876239620868-5veau2i9mtr4ofl5h04sai9hpc2nvine.apps.googleusercontent.com";
export const FACEBOOK_APP_ID = "979844743083952";
export const FACEBOOK_APP_SECRET = "772dbba055079542c031acbfb8cdbddc";
export const CONFIG_ID = "1046102856713967";

export const MIX_PANEL_TOKEN = "f1e7504fe84d3b1cd55b58f48e9204db";

export const MEASUREMENT_ID = "G-8G3D437MJZ";
export const STREAM_ID = "6489857370";
export const GTM_ID = "GTM-NBQ37V5";

export const PRODUCTION_HOST = "app.guidely.com";

// dummy
// export const FACEBOOK_APP_ID = "1556404778514241";

export const scheduleTypes = [
  {
    name: "TESTIMONIALS",
    label: "Testimonials",
    colorClass: "bg-[#EE826B]",
  },
  {
    name: "INFORMATIONAL",
    label: "Informational",
    colorClass: "bg-[#688692]",
  },
  {
    name: "CALL_TO_ACTION",
    label: "Call-to-Action (CTA)",
    colorClass: "bg-[#B7D7E4]",
  },
  {
    name: "PERSONAL_POST",
    label: "Personal Post",
    colorClass: "bg-[#C1AD3F]",
  },
  {
    name: "SHARE_YOUR_STORY",
    label: "Share your Story",
    colorClass: "bg-[#E4C270]",
  },
];
